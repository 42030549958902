import { useMutation } from 'react-query'
import { post } from 'context/api'

export type SendEventInstanceQuery = {
  id: string
  event_name: string
  location: string
  browser_session_id: string
  experiment_session_id?: string
}
export type SendEventInstanceResponse = {
  result: 'OK'
  details: string
}
export type SendEventInstanceError = {
  result: 'FAIL'
  reason: string
}

export const useSendEventInstance = () =>
  useMutation('eventInstance', (data: SendEventInstanceQuery) =>
    post<SendEventInstanceResponse, SendEventInstanceQuery>(`/details/event_instance`, data)
  )
