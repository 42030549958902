import { Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import { PropsWithChildren, useEffect, useState } from 'react'

export type HeaderProps = PropsWithChildren<{
  menuContent?: React.ReactNode
  location?: string
  onLogoClick?: () => void
}>

export const CondensedHeader = ({ children, menuContent, location, onLogoClick }: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  return (
    <header
      aria-label="Navigation Header"
      id="header-bar"
      className="bg-gray-50 w-screen shadow-md"
    >
      <div className="mx-auto h-14 w-full flex justify-start items-center gap-2 px-4">
        <img
          src="https://www.homeoptions.com/images/logo.svg"
          alt="Home Options Logo"
          className={`h-10 w-auto ${onLogoClick ? 'cursor-pointer' : ''}`}
          onClick={onLogoClick}
        />
        {children}
        <Transition
          show={!isOpen}
          className="flex justify-end flex-1"
          enter="duration-500 transition-opacity ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-500 transition-opacity ease-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Bars3Icon className="h-6 w-auto cursor-pointer" onClick={() => setIsOpen(true)} />
        </Transition>
      </div>
      <Transition
        show={isOpen}
        className="absolute bg-gray-50 shadow-lg h-screen top-0 z-50"
        enter="duration-500 transition-all"
        enterFrom="-right-full"
        enterTo="right-0"
        leave="duration-500 transition-all"
        leaveFrom="right-0"
        leaveTo="-right-full"
      >
        <XMarkIcon className="h-6 w-auto cursor-pointer m-4" onClick={() => setIsOpen(false)} />
        {menuContent}
      </Transition>
    </header>
  )
}
