import { ChatActionButton, ChatActionButtonProps } from './action-button'
import { MessageBubble, MessageBubbleProps } from './message'
import { MessageSendContainer } from './send'

export type ChatProps = {
  messages?: MessageBubbleProps[]
  actions?: ChatActionButtonProps[]
  onSend?: (message: string) => void
}

export const Chat = ({ messages, actions, onSend }: ChatProps) => {
  return (
    <div className="flex flex-col w-full h-full gap-2">
      <div className="flex flex-col items-start gap-1 flex-1">
        {messages?.map((message, index) => (
          <MessageBubble key={index} {...message} />
        ))}
      </div>
      {actions?.map((actionButton, index) => (
        <ChatActionButton key={index} {...actionButton} />
      ))}
      <MessageSendContainer onSubmit={onSend} />
    </div>
  )
}
