import React from 'react'
import { Slide, useScrollTrigger } from '@mui/material'

type HideOnScrollProps = {
  children: React.ReactElement
  direction?: 'up' | 'down'
}

export const HideOnScroll = ({ children, direction }: HideOnScrollProps) => {
  const trigger = useScrollTrigger()
  return (
    <Slide appear={false} direction={direction ?? 'down'} in={!trigger}>
      {children}
    </Slide>
  )
}
