import { createTheme, Theme } from '@mui/material';

export const rawTheme = {
  palette: {
    primary: {
      main: '#393279',
      light: '#5B5FD4',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FFA798',
      contrastText: '#24223F',
    },
    text: {
      primary: '#052633',
      secondary: '#fff',
    },
    divider: '#D9D9D9',
    background: {
      default: '#F2F6FE',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Montserrat, Open Sans, Work Sans, sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '2.25rem',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: 1.25,
    },
    h6: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.25,
    },
    subtitle1: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: 1.333,
    },
    subtitle2: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    button: {
      fontSize: '1rem',
      fontWeight: 700,
      textTransform: 'none',
    },
    body1: {
      fontSize: '1.25rem',
      fontFamily: 'Open Sans, Montserrat, Work Sans, sans-serif',
    },
    body2: {
      fontSize: '0.875rem',
      fontFamily: 'Open Sans, Montserrat, Work Sans, sans-serif',
    },
    caption: {
      fontWeight: 600,
      fontFamily: 'Open Sans, Montserrat, Work Sans, sans-serif',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 32,
          paddingRight: 32,
          '@media (min-width: 600px)': {
            paddingLeft: 32,
            paddingRight: 32,
          },
        },
      },
    },
  },
} as unknown as Theme;

export const theme = createTheme(rawTheme);

export * from './provider'
