import { useMutation } from 'react-query'
import { post } from '..'
import { useSignupData } from '../signup'

export type SelfSignupResendCodeQuery = {
  lead_id: string
  owner_no: 0 | 1
}
export type SelfSignupResendCodeResponse = {
  result: 'OK' | 'FAIL'
}
export type SelfSignupResendCodeError = {
  result: 'FAIL'
  message: string
}

export const useSelfSignupResendCode = () => {
  const { signup } = useSignupData()
  return useMutation('resendCode', (owner_no: 0 | 1) =>
    post<SelfSignupResendCodeResponse, SelfSignupResendCodeQuery>(`/resend_email_verification`, {
      lead_id: signup.lead_id ?? '',
      owner_no,
    })
  )
}
