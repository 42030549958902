import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DocumentDialog, DocumentType } from './dialog';

export const TrecFooter = () => {
  const [openModal, setOpenModal] = useState<DocumentType | undefined>(
    undefined
  );

  return (
    <>
      <Stack gap={1.5}>
        <Typography color="white" variant="button" fontWeight={700}>
          TREC
        </Typography>
        <Typography
          color="white"
          variant="body2"
          component="a"
          href="https://homeoptions.com/docs/brokerage_services.pdf"
          target="_blank"
          sx={{
            ':hover': { textDecoration: 'underline' },
            textDecoration: 'none',
            cursor: 'pointer',
          }}
        >
          Info About Brokerage Services
        </Typography>
        <Typography
          color="white"
          variant="body2"
          component="a"
          href="https://homeoptions.com/docs/consumer_protection.pdf"
          target="_blank"
          sx={{
            ':hover': { textDecoration: 'underline' },
            textDecoration: 'none',
            cursor: 'pointer',
          }}
        >
          Consumer Protection Notice
        </Typography>
      </Stack>

      <DocumentDialog
        onClose={() => setOpenModal(undefined)}
        openModal={openModal}
      />
    </>
  );
};
