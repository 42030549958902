import { useMutation } from 'react-query'
import { put } from '..'
import { useSignupData } from '../signup'
import { useSelfSignupData } from './selfSignup.atom'

export type CompleteSignupResponse = {
  result: 'OK'
  opportunity_id: string
}
export type CompleteSignupError = {
  result: 'FAIL'
  message: string
}

export const useCompleteSignup = () => {
  const { setSelfSignup } = useSelfSignupData()
  const { signup } = useSignupData()
  return useMutation(
    'CompleteSignup',
    () => put<CompleteSignupResponse, {}>(`/v2/signup/self_service/${signup.lead_id ?? ''}`),
    {
      onSuccess: (data) => {
        setSelfSignup({
          opportunity_id: data.opportunity_id,
        })
      },
    }
  )
}
