import { get } from '..'
import { useSignupData } from './signup.atom'

export type RegenerateLeadResponse = {
  result: 'OK'
  lead_id: string
  first_name: string
  last_name: string
  phone: string
  email: string
  offer_amount: number
}
export type RegenerateLeadError = {
  result: 'FAIL'
  message: string
}

export const useRegenerateLead = () => {
  const { signup, setSignup } = useSignupData()

  const appUrl = typeof window !== 'undefined' && window?.location?.host

  const regenerateLead = async () => {
    try {
      const data = await get<RegenerateLeadResponse>(
        `/v2/leads/${signup?.lead_id ?? ''}/${appUrl ?? ''}`
      )
      if (data.result === 'OK') {
        setSignup({
          lead_id: data.lead_id,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone,
          offer_amount: data.offer_amount,
          qualified: true,
          valid_owner: true,
        })
      }
    } catch (e: unknown) {
      console.error(e)
    }
  }

  return {
    regenerateLead,
  }
}
