import { useAtom } from 'jotai'
import { useAtomDevtools } from 'jotai-devtools'
import { setCookie, deleteCookie } from 'cookies-next'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'

type TestingAtom = {
  isTesting: boolean
  chatOutOfBusiness: boolean
  salesOutOfBusiness: boolean
  selfSignup: boolean
}

const storage = createJSONStorage<TestingAtom>(() => sessionStorage)
export const testingAtom = atomWithStorage<TestingAtom>(
  'testing',
  {
    isTesting: false,
    chatOutOfBusiness: false,
    salesOutOfBusiness: false,
    selfSignup: false,
  },
  storage
)

export const useTestingMode = () => {
  const [testingMode, setTestingMode] = useAtom(testingAtom)
  return {
    testingMode: process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' && testingMode.isTesting,
    testingData: testingMode,
    setTestingMode,
    activate: () => {
      setCookie('HO_testing_mode', true)
      setTestingMode({ ...testingMode, isTesting: true })
    },
    deactivate: () => {
      deleteCookie('HO_testing_mode')
      setTestingMode({ ...testingMode, isTesting: false })
    },
  }
}

export const useTestingModeDevTools = () =>
  useAtomDevtools(testingAtom, {
    name: 'testingMode',
    enabled: process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' && typeof window !== 'undefined',
  })
