import { Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useScrollOnTop } from './useScrollOnTop'

export type HeaderProps = PropsWithChildren<{
  location?: string
  logo?: JSX.Element
}>

export const Header = ({ children, location, logo }: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isOnTop } = useScrollOnTop()

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  return (
    <header
      aria-label="Navigation Header"
      id="header-bar"
      className={`w-screen fixed top-0 z-30 transition-colors duration-300 pt-6 pb-2
                  ${isOnTop ? 'bg-transparent' : 'bg-gray-50 shadow-lg '}`}
    >
      <div className="mx-auto h-14 w-full flex justify-start items-center gap-2 px-8 max-w-screen-lg">
        {logo}
        <div className="justify-end flex-1 hidden md:flex gap-8">{children}</div>
        <Transition
          show={!isOpen}
          className="flex justify-end flex-1 md:hidden"
          enter="duration-500 transition-opacity ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-500 transition-opacity ease-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Bars3Icon className="h-6 w-auto cursor-pointer" onClick={() => setIsOpen(true)} />
        </Transition>
      </div>
      <Transition
        show={isOpen}
        className="absolute bg-gray-50 shadow-lg h-screen top-0 z-50 py-10"
        enter="duration-500 transition-all"
        enterFrom="-right-full"
        enterTo="right-0"
        leave="duration-500 transition-all"
        leaveFrom="right-0"
        leaveTo="-right-full"
      >
        <div className="flex flex-col justify-start items-start px-8 gap-4">
          <XMarkIcon
            className="h-6 w-auto cursor-pointer self-end"
            onClick={() => setIsOpen(false)}
          />
          {children}
        </div>
      </Transition>
    </header>
  )
}
