const privacyPolicy = `<br/>
<b>Privacy Policy</b><br/><br/>
Effective Date: November 16, 2020<br/>
Last Reviewed on: November 16, 2020<br/>
This Privacy Policy explains how information about you is collected, used and disclosed by HomeOptions, Inc., a Delaware incorporation doing business as HomeOptions, and its subsidiaries and affiliates (collectively, “HomeOptions”, “we,” “us”, or “our”) when you access or use our website (the “Site”) and our agent matching service (the “Service”), which are provided through the Site. By using the Site and/or the Service, you consent to the collection, use and disclosure of your information as outlined in this Privacy Policy.<br/><br/>
<b>Information We Collect and How We Collect It</b><br/><br/>
In connection with your access to our Site and/or use of our Service, we collect and store certain information about you. Some of this information can be used on its own or in combination with other information to identify you individually. We call that information “personal information.” We collect personal information and other information as<br/>
described below:<br/>
· Information You Provide. We collect your personal information when you register to use the Service, provide information when using the Site or Service, update your account information, submit additional information to help us understand your transaction, contacts us with questions or feedback, or otherwise communicates with us. This personal information may include your name, address, email address, phone number, IP address, and specific detailed about the nature of your interaction with a buyer or seller or, if you are a buyer/seller, specific information about your potential transaction needs.<br/>
· Public Information. We may collect information about you from public sources, such as public social media pages or online directories.<br/>
· Information from Third Parties. We may collect and receive information about you, including personal and financial information from third parties, such as financial institutions, automated home valuation platforms, and our service providers, for identity verification, fraud protection, risk assessment, and other purposes. We may information from credit bureaus for the foregoing purposes as well. In addition, we may receive demographic information about you from third parties to help us better understand our users and to improve and market our Service.<br/>
· Automatically Collected Information. We automatically collect certain usage information when you access the Site or use the Service, such as your device identifier (if using a mobile device), Internet Protocol (IP) address (if using a browser), operating system, browser type, and the address of a referring site. We also automatically collect certain usage information through cookies and related technologies, as described below. In addition, our Site may implement third-party software, such as Google’s Invisible reCAPTCHA (the “Invisible CAPTCHA”), that collects your information for security purposes.<br/>
· Single Sign-On Information. Single Sign-On allows you to sign in to the Service from another service you use and with which we partner. We will collect certain information for security purposes in order to verify your authorized access to the Service, including your username and password for the other service. We do not currently utilize this technology but may in the future.<br/><br/>
<b>Third-Party Software, Cookies and Other Related Technologies</b><br/><br/>
We may use cookies, pixel tags, web beacons, and other similar technologies to better understand how you interact with our Site, monitor aggregate usage by our users, and monitor web traffic routing on our Site to help us improve our Site. Most Internet browsers let you change the browser’s settings to stop accepting cookies or to prompt you before accepting a cookie from websites you visit. If you do not allow cookies, you may not be able to use some or all portions or functionality of the Site or Service.<br/>
We partner with third parties to manage our advertising on other sites and to determine our Site performance. Such partners may use cookies, pixel tags, web beacons, and other related technologies to collect information about your activities on our Site and other sites so that we can (i) provide advertising that may be of interest to you, and (ii) evaluate the efficacy of our marketing programs and our Site. To prevent our partners from collecting your information for these purposes, you can visit https://preferences-mgr.truste.com to opt out of certain advertising networks.<br/>
We may use the Invisible CAPTCHA on our Site to collect information for security reasons. Use of the Invisible CAPTCHA and information collected via the Invisible CAPTCHA are subject to Google’s Terms of Service and Privacy Policy, respectively.<br/><br/>
<b>How We Use the Information We Collect</b><br/><br/>
We use information that we collect about you for the following purposes:<br/>
· to administer the Site, manage accounts and provide the Service of connecting our users to amazing Real Estate Agents;<br/>
to verify receipt of our emails or other communications;<br/>
to monitor, analyze, improve, and develop the Site and Service, and to create new Service features;<br/>
to provide a more customized experience on the Site, Service, and/or our partners’ or affiliates’ websites;<br/>
to understand our users better;<br/>
to determine eligibility for the Service;<br/>
to prevent, identify, and address fraudulent or other illegal activity and security issues;<br/>
to (i) solicit feedback, (ii) respond to you or your comments, requests, or inquiries, (iii) provide customer service and support, or (iv) otherwise contact you in connection with the Site or Service;<br/>
to generate anonymized, aggregate data containing only de-identified, non-personal information that we may use to publish reports;<br/>
for our marketing purposes, such as (i) informing you of our or our partners’ or affiliates’ products, services, features, or offerings that may be of interest to you, (ii) providing you with newsletters, articles, reports, updates, and announcements, as well as information about upcoming events, (iii) facilitating communication between buyers and sellers and our network of referral agents and ancillary service providers, (iv) improving and tailoring our advertising and communications, (v) analyzing our marketing efforts, and (vi) determining your eligibility for certain marketing programs, events, and offers;<br/>
to operate our business, which includes, without limitation, using your information (i) to verify transaction progress, (ii) to manage and enforce contracts with you or with third parties, (iii) to manage our corporate governance, compliance, and auditing practices, and (iv) for agent review and approval purposes, if you submit an application for inclusion via the Site;<br/>
to (i) comply with laws, rules, and regulations, including any disclosure or reporting obligations, (ii) resolve disputes with users or third parties, (iii) respond to claims and legal process (including but not limited to subpoenas and court orders) as we deem necessary or appropriate, (iv) protect our property rights or those of third parties, (v) protect the safety of the public or any person, and (vi) prevent or stop any activity which we may consider to be (or to pose a risk of being) illegal, unethical, or legally actionable; and<br/>
for any other purpose for which you expressly authorize us to use your information.<br/><br/>
<b>Sharing and Disclosure of Your Information</b><br/><br/>
We will only share your information with the third parties listed below for the purposes described above in the “Use of Your Information” Section:<br/>
The top performing licensed real estate agents that our algorithm connects with your particular transaction;<br/>
Marketing partners that provide ancillary services that may benefit you in your home buying or selling journey, these service providers include but are not limited to mortgage companies, insurance companies, home warranty providers, 1031 exchange qualified intermediaries, home inspectors, moving companies, retail home improvement outlets, general contractors, storage facilities, or other real estate agent referral platforms;<br/>
we may use third-party advertising companies to serve ads when you visit the Services. These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you.<br/>
banking and financial institutions;<br/>
certain parties as necessary to respond in good faith to legal process (including but not limited to subpoenas and court orders);<br/>
legal and financial advisors and auditors;<br/>
third-party agents, partners, and service providers, who (i) are only permitted to use your information as we allow (which may include contacting you on our behalf), and (ii) are required under law or contract to keep your personal information confidential; and<br/>
the following third parties under the circumstances described below<br/>
1.we may share business information with credit bureaus, and we may share information with certain companies, banks, and organizations for the purposes of fraud prevention and determining eligibility for the Service;<br/>
2.if there is a sale of HomeOptions or HomeOptions, Inc. (including, without limitation, a merger, stock acquisition, sale of assets, or reorganization), or in the event that our entity liquidates or dissolves, we may sell, transfer, or otherwise share some or all of our assets, which could include your information, to the acquirer;<br/>
3.from time to time, we may share reports with the public that contain anonymized, aggregate, de-identified information and statistics; and – we may share your information with certain other third parties with whom you expressly authorize us to share your information.<br/><br/>
<b>We do not share information with third parties for their own direct marketing purposes.</b><br/><br/><br/>
<b>Your Choices</b><br/><br/><br/>
<b>Changing or Deleting Your Information</b><br/><br/>
You may review, update, correct, or delete your personal information through your account or by contacting us using the contact information listed below. If you would like us to delete your account entirely, please contact us at contact@homeoptions.ai with a request that we delete your personal information from our database. Please note that there may be some delay in the deletion of your data from our servers following your request. Additionally, we may retain some of your data as necessary to comply with our legal obligations, resolve disputes, enforce our agreements, or as needed for other legitimate business purposes.<br/><br/>
<b>Promotional Communications</b><br/><br/>>
Phone and Text Messages. By sharing your phone number with us, you consent to receive text messages, including by automatic telephone dialing system, from us to the number provided for informational and/or marketing purposes. Consent to receive marketing messages is not a condition for service. You may opt out by texting STOP at any time or emailing contact@homeoptions.ai. If you opt out of receiving informational, marketing, and promotional messages from us, we may still need to contact you related to your account and the Service.<br/>
Emails. By sharing your email with us, you consent to receive informational, marketing, and promotional emails from us. Consent to receive marketing messages is not a condition for service. You may unsubscribe from these emails by following the opt-out instructions contained in such emails or emailing contact@homeoptions.ai. If you opt out of receiving informational, marketing, and promotional emails from us, we may still need to send you emails related to your account and the Service.<br/><br/>
<b>Do Not Track</b><br/><br/>
Our Site does not currently have the capability of responding to “Do Not Track” signals received from various browsers.<br/><br/>
<b>Security</b><br/><br/>
We employ administrative and technical measures designed to protect your information from unauthorized access and other applicable state and federal privacy and security laws; however, despite these efforts, no security measures are perfect or impenetrable and no method of data transmission can be guaranteed to prevent any interception or other type of misuse. We also depend on you to protect your information. Please set up a strong password and keep it confidential. If you become aware of any breach of security, please notify us immediately.<br/><br/>
<b>Links to Other Sites</b><br/><br/>
The Site and/or Service may contain links to other sites. Any information you provide on a third-party site is provided directly to the owner of that site and is subject to that party’s privacy policy. This Privacy Policy does not apply to such sites, and we are not responsible for the content, policies, or privacy and security practices of such sites.<br/><br/>
<b>Our Policy Toward Minors</b><br/><br/>
The Service is not directed to children under 18 and we do not knowingly collect personal information from children under 18. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at contact@homeoptions.ai.<br/><br/>
<b>Changes to this Privacy Policy</b><br/><br/>
Any information that we collect is subject to the Privacy Policy in effect at the time such information is collected. We may, however, modify and revise our Privacy Policy from time to time. If we make any material changes to this policy, we will notify you of such changes by posting them on our Site, informing you through the Service, or sending you an email or other notification, and we will indicate when such changes will become effective. By continuing to access or use our Site or Service after those changes become effective, you are agreeing to be bound by the revised policy.<br/>`;
export default privacyPolicy;
