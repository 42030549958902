import { FC } from 'react'
import {twMerge} from 'tailwind-merge'

type Variants = 'contained' | 'outlined' | 'text'
type Colors = 'primary' | 'secondary' | 'black'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: Variants
  color?: Colors
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  fullWidth?: boolean
}

const ButtonVariantClasses = {
  outlined: 'border-current',
  contained: 'bg-current border-transparent',
  text: 'border-transparent',
} as Record<Variants, string>

const ButtonColorClasses = {
  primary: 'text-brand-blue',
  secondary: 'text-brand-red',
  black: 'text-black',
} as Record<Colors, string>

export const Button: FC<ButtonProps> = ({
  children,
  variant,
  color,
  endIcon,
  startIcon,
  fullWidth,
  disabled,
  ...buttonProps
}) => {
  const variantClass = ButtonVariantClasses[variant ?? ''] ?? ''
  const colorClass = color ? ButtonColorClasses[color] : ButtonColorClasses.primary
  const textColor = disabled
    ? 'text-gray-500'
    : variant !== 'contained'
    ? 'text-current fill-current'
    : color === 'secondary'
    ? 'text-black fill-black'
    : 'text-white fill-white'

  return (
    <button
      {...buttonProps}
      onClick={(e) => {
        if (!disabled) buttonProps?.onClick?.(e)
      }}
      className={twMerge("flex justify-center items-center duration-200 transition-all ease-in rounded-sm font-semibold hover:opacity-90 py-2 px-3 border-2",
        `${fullWidth ? 'w-full' : ''}`,
        `${disabled ? 'cursor-default' : 'cursor-pointer'}`,
        `${colorClass}`, 
        `${variantClass}`, 
        `${buttonProps.className ?? ''}`, )}
    >
      {startIcon && <span className={`mr-2 ${textColor}`}>{startIcon}</span>}
      <span className={`${textColor}`}>{children}</span>
      {endIcon && <span className={`ml-2 ${textColor}`}>{endIcon}</span>}
    </button>
  )
}
