import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

type WhitePageProps = PropsWithChildren<{
  showHeader?: boolean
  showAvatar?: boolean
  avatarName?: string
  avatarOnline?: boolean
  className?: string
  internalClassName?:string
}>

export const WhitePage = ({
  children,
  showHeader,
  showAvatar,
  avatarName,
  avatarOnline,
  className,
  internalClassName,
}: WhitePageProps) => (
  <div className={twMerge(`flex justify-center container-lg mt-14 bg-brand-blue-light`, className)}>
    <div
      className={twMerge(`rounded-lg bg-white w-full max-w-screen-lg shadow-sm m-8 flex flex-col`, internalClassName)}
      style={{ height: 'calc(100vh - 95px)' }}
    >
      {showHeader && (
        <div className="flex p-2 xs:p-4 mb-2 xs:mb-4 bg-brand-purple-light items-center rounded-t-lg gap-1 xs:gap-2">
          <div
            className={`w-10 relative h-10 rounded-full bg-contain`}
            style={{
              backgroundImage: `url("/images/${showAvatar ? 'support' : 'ho'}.webp")`,
            }}
          >
            <div
              className={`absolute right-0 bottom-0 ${
                avatarOnline ? 'bg-green-600' : 'bg-gray-500'
              } rounded-full w-4 h-4`}
            />
          </div>
          <div className="flex flex-col items-start flex-1">
            <p className="text-lg font-bold">{avatarName ?? 'HomeOptions'}</p>
            <p className="text-sm font-body text-gray-500">Online</p>
          </div>
        </div>
      )}

      <div className="flex flex-col gap-1 xs:gap-2 p-2 xs:p-4 flex-1 overflow-auto">{children}</div>
    </div>
  </div>
)
