import { Disclosure, Transition } from '@headlessui/react'
import { ExpandLessRounded } from '@mui/icons-material'
import { Container, Paper } from '@mui/material'
import { useTestingMode } from 'context/state/testing.atom'
import { useRedirectToPreviousSignup } from 'hooks/useRedirectToPreviousSignup'
import { useRouter } from 'next/router'
import { Button } from 'ui'
import { HideOnScroll } from './HideOnScroll'

export const ContinueMySignupBar = () => {
  const { continueSignupAvailable, redirect } = useRedirectToPreviousSignup()
  const { testingMode } = useTestingMode()
  const router = useRouter()

  const shouldAppear = false
  //!testingMode &&
  //!router.pathname.includes('signup') &&
  //!router.pathname.includes('sell') &&
  //continueSignupAvailable

  return shouldAppear ? (
    <HideOnScroll direction="up">
      <Paper
        elevation={4}
        square
        sx={{
          width: '100vw',
          position: 'fixed',
          bottom: 0,
          left: 0,
          bgcolor: 'white',
          zIndex: 20,
          py: 2,
        }}
      >
        <Container sx={{ height: 'inherit' }}>
          <Disclosure>
            <Disclosure.Button className="flex w-full flex-col items-center text-lg text-brand-blue">
              <ExpandLessRounded className="transition-transform duration-100 ui-open:rotate-180" />
              Your signup is waiting for you!
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-50 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-100 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-50 opacity-0"
            >
              <Disclosure.Panel className="mt-2 flex justify-center">
                <Button
                  className="max-w-3xl"
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={redirect}
                >
                  Continue my signup
                </Button>
              </Disclosure.Panel>
            </Transition>
          </Disclosure>
        </Container>
      </Paper>
    </HideOnScroll>
  ) : null
}
