import { useMutation } from 'react-query'
import { post } from '..'
import { Interested, useInterestedData } from './interested.atom'

export type RegisterInterestedResponse = {
  result: 'OK'
  user_id: string
}
export type RegisterInterestedError = {
  result: 'FAIL'
  reason: string
}

export const useRegisterInterested = () => {
  const { setInterested } = useInterestedData()

  return useMutation(
    'registerInterested',
    (data: Interested) =>
      post<RegisterInterestedResponse, Interested>(`/interested/register`, data),
    {
      onSuccess: (data, query) => {
        if (data.result === 'OK') setInterested(query)
        else throw new Error()
      },
    }
  )
}
