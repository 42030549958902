import { useQuery } from 'react-query'
import { get } from '..'
import { useSignupData } from '../signup'
import { useSelfSignupData } from './selfSignup.atom'

export type CheckAllowedResponse = {
  result: 'OK'
  allowed: boolean
  owners_array?: Array<{
    first_name: string
    last_name: string
  }>
}

export const useCheckAllowed = () => {
  const { signup, setSignup } = useSignupData()
  const { setSelfSignup } = useSelfSignupData()
  return useQuery(
    'checkAllowed',
    () => get<CheckAllowedResponse>(`self_service_allowed/${signup.lead_id}`),
    {
      onError: () => {
        setSelfSignup({ allowed: false })
      },
      onSuccess: (data) => {
        if (data?.owners_array?.[1]?.first_name && data?.owners_array?.[1]?.last_name)
          setSignup({
            secondSigner: {
              ...signup.secondSigner,
              first_name: data.owners_array?.[1]?.first_name,
              last_name: data.owners_array?.[1]?.last_name,
            },
          })
        setSelfSignup({ allowed: data.allowed, owners_array: data.owners_array })
      },
    }
  )
}
