import { useAtom } from 'jotai'
import { useAtomDevtools } from 'jotai-devtools'
import { atomWithStorage } from 'jotai/utils'

export type SignupData = {
  lead_id: string
  offer_amount: number
  qualified: boolean
  valid_owner: boolean
  first_name: string
  last_name: string
  email: string
  phone: string
  referral_code: string
  isPartnerLead: boolean
  secondSigner?: Partial<{
    first_name: string
    last_name: string
    email: string
    phone: string
  }>
  signed_up:
    | boolean
    | {
        start_timestamp: string
        end_timestamp: string
      }
  signed: boolean
  in_process: boolean
  loan_amount?: number
  locked_referral_code?: boolean
  referrer?: string
}

const initialSignupState = {
  lead_id: '',
  offer_amount: 0,
  qualified: false,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  referral_code: '',
  valid_owner: false,
  signed_up: false,
  isPartnerLead: false,
  signed: false,
  in_process: false,
} as SignupData

const signupState = atomWithStorage<Partial<SignupData>>('signup', initialSignupState)

export const useSignupData = () => {
  const [signup, setSignup] = useAtom(signupState)
  return {
    signup,
    setSignup: (data: Partial<SignupData>) => setSignup({ ...signup, ...data }),
    hardSetSignup: (data: Partial<SignupData>) => setSignup({ ...initialSignupState, ...data }),
    clearSignup: () => setSignup(initialSignupState),
  }
}

export const useSignupDevtools = () =>
  useAtomDevtools(signupState, {
    name: 'signup',
    enabled: process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' && typeof window !== 'undefined',
  })
