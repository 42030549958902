import { useMutation } from 'react-query'
import { post } from '..'
import { useSignupData } from './signup.atom'
import { Suggestion } from '@homeoptions/dev.geo.address-autocomplete'
import { useToast, useClearToast } from '@homeoptions/dev.layout.toastifier'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useInterestedData } from '../interested'
import { useSelfSignupData } from '../selfSignup'

type QualifyLeadQuery = {
  address: string
  city: string
  state: string
  zip: string
}
type QualifyLeadResponse = {
  result: 'OK'
  qualified: boolean
  lead_id: string
  offer_amount: number
  signed: boolean
  in_process: boolean
}

export const useQualifyLead = () => {
  const { signup, hardSetSignup, clearSignup } = useSignupData()
  const { clearSelfSignup } = useSelfSignupData()

  const { setInterested, clearInterested } = useInterestedData()

  return useMutation(
    'qualify',
    (data: QualifyLeadQuery) =>
      post<QualifyLeadResponse, QualifyLeadQuery>('v2/signup', data),
    {
      onSuccess: ({ result, lead_id, offer_amount, qualified, in_process, signed }) => {
        if (result === 'OK' && offer_amount > 0) {
          hardSetSignup({
            lead_id,
            qualified,
            offer_amount,
            in_process,
            signed,
            referral_code: signup.referral_code ?? '',
            locked_referral_code: signup.locked_referral_code ?? false,
          })
        }
      },
      onMutate: ({ address, city, state, zip }) => {
        clearSignup()
        clearSelfSignup()
        clearInterested()
        setInterested({
          address_submitted: `${address}, ${city}, ${state} - ${zip}`,
        })
      },
    }
  )
}

export const useQualifyLeadHandler = () => {
  const router = useRouter()
  const toast = useToast()
  const clearToast = useClearToast()
  const [isLoading, setIsLoading] = useState(false)
  const { mutate, isError, isSuccess, data, isLoading: loadingRequest } = useQualifyLead()

  const [selectedAddress, setSelectedAddress] = useState<Suggestion | undefined>()

  const handleAddressChange = (address: Suggestion) => setSelectedAddress(address)

  useEffect(() => {
    if (router.pathname === '/signup/qualified' || router.pathname === '/signup/not_qualified/') {
      clearToast()
      setIsLoading(false)
    }
  }, [router.pathname])

  useEffect(() => {
    if (isSuccess && data.qualified) router.push('/signup/qualified')
    else if (isSuccess && !data.qualified) router.push('/signup/not_qualified')
  }, [isSuccess, router, data])

  useEffect(() => {
    if (isError) router.push('/signup/not_qualified')
  }, [isError, router])

  useEffect(() => {
    if (loadingRequest) {
      setIsLoading(true)
      toast({
        message: 'Checking your address...',
        severity: 'info',
        disableClose: true,
      })
    }
  }, [loadingRequest, toast])

  const onConfirmAddress = () => {
    if (selectedAddress) {
      mutate({
        address: selectedAddress.street_line,
        city: selectedAddress.city,
        state: selectedAddress.state,
        zip: selectedAddress.zipcode,
      })
    }
  }

  return {
    handleAddressChange,
    onConfirmAddress,
    selectedAddress,
    isLoading,
  }
}
