import { useAtom } from 'jotai'
import { useAtomDevtools } from 'jotai-devtools'
import { atomWithStorage } from 'jotai/utils'

export type SelfSignupData = {
  allowed: boolean
  opportunity_id: string
  otp_status: 'approved' | string
  owners_array?: Array<{
    first_name: string
    last_name: string
  }>
}

const initialSelfSignupState = {
  allowed: false,
  otp_status: '',
  opportunity_id: '',
}

const selfSignupState = atomWithStorage<Partial<SelfSignupData>>(
  'selfSignup',
  initialSelfSignupState
)

export const useSelfSignupData = () => {
  const [selfSignup, setSelfSignup] = useAtom(selfSignupState)
  return {
    selfSignup,
    hardSetSelfSignup: (data: Partial<SelfSignupData>) => setSelfSignup(data),
    setSelfSignup: (data: Partial<SelfSignupData>) => setSelfSignup({ ...selfSignup, ...data }),
    clearSelfSignup: () => setSelfSignup(initialSelfSignupState),
  }
}

export const useSelfSignupDevtools = () =>
  useAtomDevtools(selfSignupState, {
    name: 'selfSignup',
    enabled: process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' && typeof window !== 'undefined',
  })
