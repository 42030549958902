import React from 'react'

export const IconButton = ({
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const ChildrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        className: 'absolute fill-white text-white -translate-x-1/2 -translate-y-1/2 left-1/2',
      } as any)
    }
    return child
  })

  return (
    <button
      {...props}
      className={`rounded-full bg-brand-blue p-2 relative min-w-[2.5rem] h-10 hover:opacity-95 
        duration-200 transition-all ease-in 
                  disabled:bg-gray-500 ${props.className}`}
    >
      {ChildrenWithProps}
    </button>
  )
}
