import { useMutation } from 'react-query'
import { put } from '..'
import { useSignupData } from './signup.atom'

export type SignupEmailQuery = {
  email: string
  consent: boolean
}
export type SignupEmailResponse = {
  result: 'OK'
}
export type SignupEmailError = {
  result: 'FAIL'
  message: string
}

export const useSignupEmail = () => {
  const { signup, setSignup } = useSignupData()

  return useMutation(
    'signupEmail',
    (data: Omit<SignupEmailQuery, 'consent'>) =>
      put<SignupEmailResponse, SignupEmailQuery>(`/v2/signup/email/${signup.lead_id ?? ''}`, {
        ...data,
        consent: true,
      }),
    {
      onSuccess: (_data, query) => {
        setSignup({ email: query.email })
      },
    }
  )
}
