import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DocumentDialog, DocumentType } from './dialog';

export const LegalFooter = () => {
  const [openModal, setOpenModal] = useState<DocumentType | undefined>(
    undefined
  );

  return (
    <>
      <Stack gap={1.5}>
        <Typography color="white" variant="button" fontWeight={700}>
          LEGAL
        </Typography>
        <Typography
          color="white"
          variant="body2"
          onClick={() => setOpenModal('privacy')}
          sx={{
            ':hover': { textDecoration: 'underline' },
            cursor: 'pointer',
          }}
        >
          Privacy Policy
        </Typography>
        <Typography
          color="white"
          variant="body2"
          onClick={() => setOpenModal('terms')}
          sx={{
            ':hover': { textDecoration: 'underline' },
            cursor: 'pointer',
          }}
        >
          Terms & Conditions
        </Typography>
        <Typography
          color="white"
          variant="body2"
          onClick={() => setOpenModal('licenses')}
          sx={{
            ':hover': { textDecoration: 'underline' },
            cursor: 'pointer',
          }}
        >
          State Licenses
        </Typography>
      </Stack>
      <DocumentDialog
        onClose={() => setOpenModal(undefined)}
        openModal={openModal}
      />
    </>
  );
};
