import { useToast } from '@homeoptions/dev.layout.toastifier'
import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { put } from '..'
import { useSignupData } from './signup.atom'

export type SignupNameQuery = {
  first_name: string
  last_name: string
}
export type SignupNameResponse = {
  result: 'OK'
  valid_owner: boolean
}
export type SignupNameError = {
  result: 'FAIL'
  message: string
}

export const useSignupName = () => {
  const { signup, setSignup } = useSignupData()

  const toast = useToast()

  return useMutation(
    'signupName',
    (data: SignupNameQuery) =>
      put<SignupNameResponse, SignupNameQuery>(`/v2/signup/name/${signup.lead_id ?? ''}`, data),
    {
      onSuccess: (data, query) => {
        setSignup({ ...query, valid_owner: data.valid_owner ?? false })
        if (!data?.valid_owner) {
          toast({
            message: "Sorry the names don't match",
            severity: 'error',
          })
        }
      },
      onError: (error: AxiosError, query) => {
        const errorMessage = (error?.response?.data as SignupNameError)?.message
        if (errorMessage === 'Names already provided') setSignup({ ...query, valid_owner: true })
        else
          toast({
            message: errorMessage ?? "Sorry the names don't match",
            severity: 'error',
          })
      },
      onMutate: () => {
        toast({
          message: 'Checking up your name...',
          severity: 'info',
        })
      },
    }
  )
}
