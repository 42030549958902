import { useAtom } from 'jotai'
import { useAtomDevtools } from 'jotai-devtools'
import { atomWithStorage } from 'jotai/utils'

export type Interested = {
  email: string
  first_name: string
  middle_name: string
  last_name: string
  address_submitted: string
}

export const interestedState = atomWithStorage<Partial<Interested>>('interested', {
  email: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  address_submitted: '',
})

export const useInterestedData = () => {
  const [interested, setInterested] = useAtom(interestedState)
  return {
    interested,
    hardSetInterested: (data: Partial<Interested>) => setInterested(data),
    setInterested: (data: Partial<Interested>) => setInterested({ ...interested, ...data }),
    clearInterested: () => setInterested({}),
  }
}

export const useInterestedDevtools = () =>
  useAtomDevtools(interestedState, {
    name: 'interested',
    enabled: process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' && typeof window !== 'undefined',
  })
