import { useMutation } from 'react-query'
import { post } from '..'
import { useSignupData } from '../signup'
import { useSelfSignupData } from './selfSignup.atom'

export type SelfSignupCheckOTPQuery = {
  otp_code: string
  lead_id: string
  owner_no: 0 | 1
}
export type SelfSignupCheckOTPResponse = {
  result: 'OK'
  otp_status: 'approved' | string
}
export type SelfSignupCheckOTPError = {
  result: 'FAIL'
  message: string
}

export const useSelfSignupCheckOTP = () => {
  const { setSelfSignup } = useSelfSignupData()
  const { signup } = useSignupData()
  return useMutation(
    'checkOtp',
    (data: { otp_code: string; owner_no: 0 | 1 }) =>
      post<SelfSignupCheckOTPResponse, SelfSignupCheckOTPQuery>(`/check_otp`, {
        ...data,
        lead_id: signup.lead_id ?? '',
      }),
    {
      onSuccess: (data) => {
        setSelfSignup({
          otp_status: data.otp_status,
        })
      },
    }
  )
}
