export type BucketData = {
  variants: string[]
  activationSearchParams?: string[]
  experimentId: string
}

export const Buckets = {
  '/about': {
    variants: ['kevin', 'no-kevin'],
    experimentId: 'about-us-kevin-picture',
  },
  '/signup/qualified': {
    variants: ['outbound', 'inbound'],
    experimentId: 'partner-redirect-inbound-chat',
    activationSearchParams: ['lead_id'],
  },
} as Record<string, BucketData>
