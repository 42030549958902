import { FC, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  UnfoldMoreRounded,
} from '@mui/icons-material'

type SelectValueType = string | number | readonly string[]
export interface SelectProps {
  value?: SelectValueType
  onChange?: (value: SelectValueType) => void
  options?: { label: string; value: string | number }[]
  label: string
  multiple?: boolean
  disabled?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  fullWidth?: boolean
  className?: string
  blockPopup?: boolean
}

export const CondensedSelect: FC<SelectProps> = ({
  startIcon,
  endIcon,
  value,
  onChange,
  options,
  multiple,
  disabled,
  label,
  fullWidth,
  className,
  blockPopup
}) => {
  const selectedValue = options?.find((option) => option.value === value)
  return (
    <Listbox value={value} onChange={onChange} multiple={multiple} disabled={disabled}>
      <div className={`${blockPopup ? '' : 'relative'} w-full flex flex-col gap-0.5`}>
        <span className="text-gray-700 text-sm font-body tracking-tight">{label}</span>
        <div
          className={`${fullWidth ? 'w-full' : ''} ${
            className ?? ''
          } flex gap-1 items-center`}
        >
          {startIcon}
          <Listbox.Button
            className={`border border-gray-400 rounded-sm py-1 px-2 font-body flex 
              bg-white items-center justify-between flex-1 ${
                endIcon ? 'max-w-[calc(100%-30px)]' : 'max-w-full'
              }`}
          >
            <span className="max-w-[calc(100%-24px)] text-ellipsis overflow-hidden">
              {selectedValue?.label ?? label}
            </span>
            <UnfoldMoreRounded fontSize="small" />
          </Listbox.Button>
          {endIcon}
        </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`${blockPopup ? '' : 'absolute'} z-20 w-full max-h-60 overflow-y-auto bg-white rounded-sm
                top-16 border-2 border-gray-200 shadow-lg min-w-fit`}
            >
              {options?.map((opt) => (
                <Listbox.Option key={opt.value} value={opt.value}>
                  {({ selected, active }) => (
                    <span
                      className={`cursor-pointer p-2 flex flex-row items-center gap-2 
                        first:rounded-t-sm last:rounded-b-sm ${
                          active ? 'bg-brand-blue-light' : ''
                        } ${selected ? 'bg-brand-purple-light' : ''}`}
                    >
                      {!multiple ? null : selected ? (
                        <CheckBoxRounded />
                      ) : (
                        <CheckBoxOutlineBlankRounded />
                      )}
                      {opt.label}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
      </div>
    </Listbox>
  )
}
