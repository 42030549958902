import React from 'react'
import { HandshakeRounded, HelpOutlineRounded } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'

type SupportFooterProps = {
  hideCommercialLine?: boolean
}
export const SupportFooter = ({ hideCommercialLine }: SupportFooterProps) => (
  <Stack gap={1}>
    {!hideCommercialLine && (
      <Stack gap={1.5}>
        <Stack direction="row" alignItems="center" gap={1}>
          <HandshakeRounded sx={{ color: 'white' }} />
          <Typography color="white" variant="button" textTransform="none">
            New Customers
          </Typography>
        </Stack>
        <Typography
          color="white"
          variant="body2"
          component="a"
          href="mailto:join@homeoptions.ai"
          sx={{
            ':hover': { textDecoration: 'underline' },
            textDecoration: 'none',
            cursor: 'pointer',
            ml: 4,
          }}
        >
          join@homeoptions.ai
        </Typography>
        <Typography
          color="white"
          variant="body2"
          component="a"
          href="tel:800-949-0755"
          sx={{
            ':hover': { textDecoration: 'underline' },
            textDecoration: 'none',
            cursor: 'pointer',
            ml: 4,
          }}
        >
          800-949-0755
        </Typography>
      </Stack>
    )}
    <Stack gap={1.5}>
      <Stack direction="row" alignItems="center" gap={1}>
        <HelpOutlineRounded sx={{ color: 'white' }} />
        <Typography color="white" variant="button" textTransform="none">
          Support
        </Typography>
      </Stack>
      <Typography
        color="white"
        variant="body2"
        component="a"
        href="mailto:support@homeoptions.ai"
        sx={{
          ':hover': { textDecoration: 'underline' },
          textDecoration: 'none',
          cursor: 'pointer',
          ml: 4,
        }}
      >
        support@homeoptions.ai
      </Typography>
      <Typography
        color="white"
        variant="body2"
        component="a"
        href="tel:800-357-9992"
        sx={{
          ':hover': { textDecoration: 'underline' },
          textDecoration: 'none',
          cursor: 'pointer',
          ml: 4,
        }}
      >
        800-357-9992
      </Typography>
    </Stack>
  </Stack>
)
