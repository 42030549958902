import { CloseRounded } from '@mui/icons-material'
import { Dialog, IconButton, Typography } from '@mui/material'
import React, { FC } from 'react'
import privacyPolicy from './assets/privacy-policy'
import stateLicenses from './assets/state-licenses'
import termsAndConditions from './assets/terms-and-conditions'

export type DocumentType = 'privacy' | 'terms' | 'licenses'
const documentDictionary = {
  privacy: privacyPolicy,
  terms: termsAndConditions,
  licenses: stateLicenses,
} as Record<DocumentType, string>

export const DocumentDialog: FC<{
  openModal: DocumentType | undefined
  onClose: () => void
}> = ({ openModal, onClose }) => {
  return (
    <Dialog open={!!openModal} onClose={() => onClose()} PaperProps={{ sx: { p: 2 } }}>
      <IconButton
        onClick={() => onClose()}
        sx={{ alignSelf: 'flex-end', position: 'sticky', top: 0, right: 0 }}
      >
        <CloseRounded />
      </IconButton>
      {openModal && (
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: documentDictionary[openModal] }}
        />
      )}
    </Dialog>
  )
}
