import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export type Request = <T>(url: string, config?: AxiosRequestConfig) => Promise<T>
export type SideEffectRequest = <T, D>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<any>
) => Promise<T>

export const instance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/`,
  timeout: 15000,
  headers: {
    Authorization: `Basic ${process.env.NEXT_PUBLIC_PARTNER_STRING}:${process.env.NEXT_PUBLIC_KEY_STRING}`,
  },
})

export const put: SideEffectRequest = (url, data, config) =>
  instance
    .put(
      url,
      {
        ...data,
        key: process.env.NEXT_PUBLIC_KEY_STRING,
        partner: process.env.NEXT_PUBLIC_PARTNER_STRING,
      },
      config
    )
    .then((d) => d.data)

export const post: SideEffectRequest = (url, data, config) =>
  instance
    .post(
      url,
      {
        ...data,
        key: process.env.NEXT_PUBLIC_KEY_STRING,
        partner: process.env.NEXT_PUBLIC_PARTNER_STRING,
      },
      config
    )
    .then((d) => d.data)

export const get: Request = (url, config) => instance.get(url, config).then((d) => d.data)
