import {useTestingMode} from 'context/state/testing.atom'
import { useQuery } from 'react-query'
import { get } from '..'
import { useInterestedData } from '../interested'
import { useSelfSignupData } from '../selfSignup'
import { useSignupData } from './signup.atom'

export type PartnerLeadResponse = {
  result: 'OK'
  lead_id: string
  first_name: string
  last_name: string
  phone: string
  email: string
  offer_amount: number
  address?: string
  loan_amount?: number
  referrer?: string
  in_process?: boolean
  signed?: boolean
}
export type PartnerLeadError = {
  result: 'FAIL'
  message: string
}

export const usePartnerLead = (lead_id?: string, initialData?: PartnerLeadResponse) => {
  const { hardSetSignup } = useSignupData()
  const { clearSelfSignup } = useSelfSignupData()
  const { setInterested } = useInterestedData()
  const { testingMode } = useTestingMode()

  const appUrl = typeof window !== 'undefined' && window?.location?.host
  return useQuery(
    ['showPartnerLead', lead_id],
    () => get<PartnerLeadResponse>(`/v2/leads/${lead_id ?? ''}/${appUrl ?? ''}`),
    {
      onSuccess: (data) => {
        clearSelfSignup()
        setInterested({
          email: data.email,
          address_submitted: data.address ?? '',
          first_name: data.first_name,
          last_name: data.last_name,
        })
        hardSetSignup({
          lead_id: data.lead_id,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone,
          offer_amount: data.offer_amount,
          isPartnerLead: true,
          qualified: true,
          valid_owner: true,
          referrer: data.referrer,
          loan_amount: data.loan_amount,
          in_process: data.in_process,
          signed: data.signed,
        })
      },
      initialData,
      refetchOnWindowFocus: false,
      enabled: !testingMode && !!lead_id && lead_id.length > 0,
    }
  )
}
