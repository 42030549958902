export default `
<b>State Licenses</b><br/><br/>
California DRE License #02141969<br/>
Colorado License #100093442<br/>
Georgia License #79635<br/>
Nevada License #B.1002721<br/>
Virginia License #0225255205<br/>
Washington License #21017148<br/>
Florida License #CQ1065003<br/>
Texas License #9011979<br/>`;
