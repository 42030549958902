import { Grow } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'

export type MessageAction = {
  label: string
  cta?: boolean
  onClick?: () => void
  phoneNumber?: string
}

export type MessageBubbleProps = {
  message?: string
  children?: ReactElement
  isOwn?: boolean
  actions?: MessageAction[]
  isLast?: boolean
  isFirst?: boolean
  sentDate?: string
  senderName?: string
  className?: string
  delay?: number
  timestamp?: string
  disableAnimation?: boolean
  additionalinfo?: string
}

const parseMessage = (message: string) => {
  let parsedMessage = message
  while (parsedMessage.includes('**')) {
    parsedMessage = parsedMessage.replace('**', '<b>').replace('**', '</b>')
  }
  return parsedMessage
}

export const MessageBubble = ({
  message,
  children,
  isOwn,
  actions,
  isLast,
  isFirst,
  senderName,
  className,
  delay,
  disableAnimation,
  timestamp,
  additionalinfo,
}: MessageBubbleProps) => {
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    if (showMessage === false)
      setTimeout(() => {
        setShowMessage(true)
      }, delay ?? 200)
  }, [showMessage, delay])

  return (
    <Grow appear={!disableAnimation} in={showMessage} timeout={500}>
      <div
        className={`py-2 rounded relative max-w-[90%] mx-[12px] 
      ${
        isFirst &&
        `after:absolute after:content-[''] after:top-[12px] after:w-0 after:h-0 after:border-[12px] after:border-t-0
          after:-mt-[12px] after:border-transparent`
      }
      ${
        isOwn
          ? `bg-brand-blue-light self-end ${isFirst && 'rounded-tr-none'} ${
              isFirst &&
              `after:right-[12px] after:border-l-brand-blue-light after:border-r-0 after:-mr-[24px]`
            }`
          : `bg-brand-purple-light ${isFirst && 'rounded-tl-none'} ${
              isFirst &&
              `
            after:left-[12px] after:border-r-brand-purple-light after:border-l-0 after:-ml-[24px]`
            }`
      } ${className ?? ''}`}
      >
        {isFirst && senderName && (
          <span className="px-3 font-body text-md font-bold">{senderName}</span>
        )}
        <p
          className="px-3 font-body"
          dangerouslySetInnerHTML={message?.length ? { __html: parseMessage(message) } : undefined}
        >
          {children}
        </p>
        {timestamp && <span className="text-xs text-gray-500 px-3 float-right">{timestamp}</span>}
        {additionalinfo && (
          <span className="text-xs text-gray-500 px-3 float-right">{additionalinfo}</span>
        )}
        {actions && isLast && (
          <div
            className={`grid grid-cols-1 divide-y border-t mt-2 ${
              isOwn ? 'border-gray-200' : 'border-purple-dark divide-purple-dark'
            }`}
          >
            {actions.map((action) => action.phoneNumber ? (              
            <a
                href={`tel:${action.phoneNumber}`}
                id="call_us_button"
                key={action.label}
                onClick={action.onClick}
                className={`w-full py-2 px-3 font-body rounded-sm light font-bold text-lg
                uppercase tracking-tight bg-brand-blue text-white rounded-b rounded-t-none
                -mb-2 text-center`}
              >
                {action.label}
              </a>
            ): (
              <button
                key={action.label}
                onClick={action.onClick}
                className={`w-full py-2 px-3 font-body rounded-sm light  ${
                  action.cta
                    ? 'font-bold text-lg uppercase tracking-tight bg-brand-blue text-white rounded-b rounded-t-none -mb-2'
                    : 'text-brand-blue'
                }`}
              >
                {action.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </Grow>
  )
}
