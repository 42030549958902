import { Link, Stack, Typography } from '@mui/material';

export const AboutUsFooter = () => (
  <Stack gap={1.5}>
    <Typography color="white" variant="button" fontWeight={700}>
      ABOUT US
    </Typography>
    <Link href="https://homeoptions.com/about#mission">
      <Typography color="white" variant="body2">
        Our mission
      </Typography>
    </Link>
    <Link href="https://homeoptions.com/about#markets">
      <Typography color="white" variant="body2">
        Our markets
      </Typography>
    </Link>
  </Stack>
);
