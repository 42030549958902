import { Buckets } from 'assets/static/buckets'
import { useSendBrowserSession } from 'context/api/experiments/browserSession.request'
import { useSendEventInstance } from 'context/api/experiments/eventInstance.request'
import { useSendExperimentSession } from 'context/api/experiments/experimentSession.request'
import { useSignupData } from 'context/api/signup'
import { getCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { createId } from '@paralleldrive/cuid2'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const getExperimentCookies = () => {
  const rawVisitorId = getCookie('visitor_id')
  const visitorId = rawVisitorId !== true ? rawVisitorId : undefined
  const visitorBuckets = getCookie('visitor_buckets')
  return { visitorId, visitorBuckets }
}

const useCurrentExperiment = () => {
  const router = useRouter()
  const bucket = Buckets[router.asPath.split(/([#?])/)[0]]

  const isBucketActive = bucket?.activationSearchParams
    ? bucket.activationSearchParams.every((param) =>
        Object.keys(router.query ?? {}).includes(param)
      )
    : true

  return isBucketActive && bucket ? bucket.experimentId : undefined
}

const useCurrentBucket = () => {
  const router = useRouter()
  const currentExperiment = useCurrentExperiment()
  const currentCookie = useMemo(() => getCookie('visitor_buckets'), [router.asPath.split('?')[0]])

  const cookieAndExperimentExists =
    currentCookie && currentCookie?.toString().length > 0 && currentExperiment

  if (cookieAndExperimentExists) {
    const buckets = JSON.parse(currentCookie?.toString() ?? 'undefined')
    return buckets[currentExperiment]
  } else return undefined
}

const getUserData = () => {
  if (typeof navigator !== 'undefined') {
    return {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
    }
  } else return {}
}

const useBrowserSession = () => {
  const router = useRouter()
  const [browserSession, setBrowserSession] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!browserSession) {
      const sessionId = sessionStorage.getItem('browserSession') ?? createId()
      sessionStorage.setItem('browserSession', sessionId)
      setBrowserSession(sessionId)
    }
  }, [router.pathname])

  return browserSession
}

const useDeviceInfo = () => {
  if (typeof screen !== 'undefined') {
    return {
      deviceWidth: screen.width,
      referrer: document.referrer,
    }
  } else return {}
}

export const useExperiments = () => {
  const currentBucket = useCurrentBucket()
  const currentExperiment = useCurrentExperiment()
  const { userAgent, platform } = getUserData()
  const { visitorId } = getExperimentCookies()
  const browserSession = useBrowserSession()
  const { deviceWidth, referrer } = useDeviceInfo()
  const { pathname } = useRouter()
  const [experimentSession, setExperimentSession] = useState<string | undefined>(undefined)

  const { mutate: sendBrowserSession } = useSendBrowserSession()
  const { mutate: sendExperimentSession } = useSendExperimentSession()
  const { mutate: sendEventInstance } = useSendEventInstance()

  const sendButtonClick = useCallback(
    (buttonName: string) => {
      if (pathname && browserSession && experimentSession)
        sendEventInstance({
          id: createId(),
          browser_session_id: browserSession,
          event_name: `button_click:${buttonName}`,
          experiment_session_id: experimentSession,
          location: pathname,
        })
    },
    [browserSession, experimentSession, pathname]
  )

  const { signup } = useSignupData()
  const leadId = signup.lead_id ?? ''

  useEffect(() => {
    if (visitorId && browserSession) {
      sendBrowserSession({
        id: browserSession,
        visitor_id: visitorId,
        start_date: new Date().toISOString(),
        country_code: 'WIP',
        device: deviceWidth?.toString() ?? '',
        browser: userAgent ?? '',
      })
    }
  }, [browserSession, visitorId])

  useEffect(() => {
    if (pathname && currentExperiment) setExperimentSession(createId())
  }, [pathname, currentExperiment])

  useEffect(() => {
    if (visitorId && currentExperiment && currentBucket && browserSession && experimentSession) {
      sendExperimentSession({
        id: experimentSession,
        bucket: currentBucket,
        start_date: new Date().toISOString(),
        lead_id: leadId,
        browser_session_id: browserSession,
        experiment_id: currentExperiment,
        entrypoint: referrer ?? '',
      })
    }
  }, [currentBucket, currentExperiment, browserSession, leadId, experimentSession])

  useEffect(() => {
    if (pathname && browserSession)
      sendEventInstance({
        id: createId(),
        browser_session_id: browserSession,
        event_name: 'page_view',
        experiment_session_id: experimentSession,
        location: pathname,
      })
  }, [pathname, browserSession, experimentSession])

  return {
    visitorId,
    visitorBuckets: currentBucket,
    currentExperiment,
    sendButtonClick,
    userAgent,
    platform,
    browserSession,
    deviceWidth,
  }
}
