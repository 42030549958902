import { useEffect, useState } from 'react';

export const useScrollOnTop = () => {
  const [isOnTop, setIsOnTop] = useState(true);
  useEffect(() => {
    const onScroll = () => {
      setIsOnTop(window.scrollY === 0);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return { isOnTop };
};
