import { ChangeEvent, FC, InputHTMLAttributes, useEffect, useRef, useState } from 'react'

type Type = InputHTMLAttributes<HTMLInputElement>['type'] | 'usd' | 'percentage'

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  helperText?: string
  error?: boolean
  fullWidth?: boolean
  endIcon?: React.ReactNode
  inputClassName?: string
  type?: Type
}

const getInitialValue = (v: string | undefined, type: Type) => {
  if (!v) return ''
  if (type === 'usd') {
    const formatter = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })
    return `${formatter.format(Number(v))}`
  }
  if (type === 'percentage') {
    const formatter = Intl.NumberFormat('en-US', { maximumFractionDigits: 2 })
    return `${formatter.format(Number(v))}%`
  }
  return v
}
export const CondensedTextField: FC<TextFieldProps> = ({
  className,
  helperText,
  label,
  error,
  endIcon,
  fullWidth,
  inputClassName,
  onChange,
  type,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState<string>(
    getInitialValue(props.value?.toString(), type)
  )

  const ref = useRef<HTMLInputElement>(null)
  const currentType = type === 'usd' || type === 'percentage' ? 'text' : type

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (type === 'usd') {
      const actualValue = e.target.value.replace(/[^0-9.]/g, '')
      const formatter = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      })
      setInternalValue(actualValue?.length ? `${formatter.format(Number(actualValue))}` : '')
      onChange?.({ ...e, target: { ...e.target, value: actualValue } })
    } else if (type === 'percentage') {
      const formatter = Intl.NumberFormat('en-US', { maximumFractionDigits: 2 })
      const numericValue = e.target.value.replace(/[^0-9.]/g, '')
      const value =
        Number(numericValue) > 100 ? '100' : Number(numericValue) < 0 ? '' : numericValue
      const formattedValue = formatter.format(Number(value))
      setInternalValue(value.length > 0 ? `${formattedValue}%` : value)
      onChange?.({ ...e, target: { ...e.target, value } })
    } else {
      setInternalValue(e.target.value)
      onChange?.(e)
    }
  }

  useEffect(() => {
    if (type === 'percentage')
      ref.current?.setSelectionRange(ref.current.value.length - 1, ref.current.value.length - 1)
  }, [internalValue, type])

  return (
    <div className={`flex flex-col gap-0.5 ${fullWidth ? 'w-full' : ''} ${className ?? ''}`}>
      <label className="font-body text-sm text-gray-700 tracking-tight">{label}</label>
      <div className="relative w-fit">
        <input
          style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
          onKeyDown={() => {
            if (type === 'percentage')
              ref.current?.setSelectionRange(
                ref.current.value.length - 1,
                ref.current.value.length - 1
              )
            else if (type === 'usd')
              ref.current?.setSelectionRange(
                ref.current.value.length + 1,
                ref.current.value.length + 1
              )
          }}
          onKeyUp={() => {
            if (type === 'usd')
              ref.current?.setSelectionRange(
                ref.current.value.length + 1,
                ref.current.value.length + 1
              )
          }}
          {...props}
          ref={ref}
          className={`border border-gray-400 rounded-sm py-1 px-2 font-body ${
            error ? 'border-red-500' : ''
          } ${inputClassName ?? ''} ${endIcon ? 'pr-9' : ''}`}
          value={internalValue}
          type={currentType}
          onChange={handleChange}
        />
        {endIcon && <div className="absolute right-3 top-1/2 -translate-y-1/2">{endIcon}</div>}
      </div>
      {helperText && <p className="font-body text-sm text-gray-500">{helperText}</p>}
    </div>
  )
}
