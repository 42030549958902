import { useMutation } from 'react-query'
import { put } from '..'
import { useSignupData } from './signup.atom'

export type ScheduleLeadQuery = {
  phone: string
  calendly_event_url: string
  calendly_invitee_url: string
  referral_code?: string
  consent?: boolean
}
export type ScheduleLeadResponse = {
  result: 'OK'
  event_details: {
    start_timestamp: string
    end_timestamp: string
  }
}
export type ScheduleLeadError = {
  result: 'FAIL'
  message: string
}

export const useScheduleLead = () => {
  const { signup, setSignup } = useSignupData()

  return useMutation(
    'scheduleLead',
    (data: Omit<ScheduleLeadQuery, 'consent'>) =>
      put<ScheduleLeadResponse, ScheduleLeadQuery>(`/v2/signup/schedule/${signup.lead_id ?? ''}`, {
        ...data,
        consent: true,
      }),
    {
      onSuccess: (data, query) => {
        setSignup({
          signed_up: data.event_details,
          phone: query.phone,
          referral_code: query.referral_code,
        })
      },
    }
  )
}
