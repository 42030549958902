import { useMutation } from 'react-query'
import { post } from 'context/api'

export type SendBrowserSessionQuery = {
  id: string
  visitor_id: string
  start_date: string
  country_code: string
  browser: string
  device: string
}
export type SendBrowserSessionResponse = {
  result: 'OK'
  details: string
}
export type SendBrowserSessionError = {
  result: 'FAIL'
  reason: string
}

export const useSendBrowserSession = () =>
  useMutation('browserSession', (data: SendBrowserSessionQuery) =>
    post<SendBrowserSessionResponse, SendBrowserSessionQuery>(`/details/browser_session`, data)
  )
