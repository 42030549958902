import { LightModeOutlined } from '@mui/icons-material'
import { Container, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { HomeOptionsBrand } from 'ui'
import { AboutUsFooter } from './footer.about'
import { LegalFooter } from './footer.legal'
import { SupportFooter } from './footer.support'
import { TrecFooter } from './footer.trec'

type FooterProps = {
  children?: React.ReactNode
}

export const Footer = ({ children }: FooterProps) => {
  return (
    <>
      <Stack width="100vw" className="bg-brand-blue" pt={6} pb={2} gap={3}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <HomeOptionsBrand className="h-10 w-auto text-white" />
            </Grid>
            <Grid item xs={12} container spacing={4}>
              <Grid item xs={12} sm={6} md={3}>
                <AboutUsFooter />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <LegalFooter />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TrecFooter />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <SupportFooter />
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Stack
            direction="row"
            justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
            alignItems="center"
            flexWrap="wrap"
            flex={1}
            gap={2}
            py={4}
          >
            <LightModeOutlined sx={{ color: '#fff' }} />
            <Typography color="white" variant="body2">
              CA DRE License #02141969
            </Typography>
            <Stack flex={1}>
              <Typography
                minWidth={180}
                color="white"
                textAlign={{ xs: 'left', sm: 'right' }}
                variant="body2"
              >
                Copyright © {new Date().getFullYear()} HomeOptions, Inc. All Rights Reserved.
              </Typography>
              <Typography
                minWidth={180}
                color="white"
                textAlign={{ xs: 'left', sm: 'right' }}
                variant="body2"
              >
                Also operating as HomeOptions Real Estate, LLC.
              </Typography>
            </Stack>
          </Stack>
          {children}
        </Container>
      </Stack>
    </>
  )
}
