//import { useInterestedDevtools } from 'context/api/interested'
//import { usePaymentDevtools } from 'context/api/payment/payment.atom'
//import { useSelfSignupDevtools } from 'context/api/selfSignup'
//import { useSellingPagesDevtools } from 'context/api/selling/selling.atom'
//import { useSignupDevtools } from 'context/api/signup'
//import { useSignupChatDevtools } from 'context/api/signupChat'
//import { useTestingModeDevTools } from 'context/state/testing.atom'
import { FC, ReactElement } from 'react'

type AtomsDevtoolsProps = {
  children: ReactElement | null
}
export const AtomsDevtools: FC<AtomsDevtoolsProps> = ({ children }) => {
  //useInterestedDevtools()
  //useSelfSignupDevtools()
  //useSignupDevtools()
  //usePaymentDevtools()
  //useTestingModeDevTools()
  //useSellingPagesDevtools()
  //useSignupChatDevtools()
  return children
}
