import { SelfSignupData, useSelfSignupData } from 'context/api/selfSignup'
import { SignupData, useSignupData } from 'context/api/signup'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const redirectDictionary = {
  '/signup/qualified/email': (data) => data.first_name && data.last_name,
  '/signup/qualified/next_steps': (data) => data.email,
  '/signup/qualified/self_signup': (data) => data.allowed && data.opportunity_id,
  '/signup/qualified/self_signup/review': (data) => data.allowed && data.otp_status === 'approved',
} as Record<string, (_data: CombinedSignupData) => void>

type CombinedSignupData = Partial<SignupData & SelfSignupData>

export const useRedirectToPreviousSignup = () => {
  const { signup } = useSignupData()
  const { selfSignup } = useSelfSignupData()

  const router = useRouter()

  const [path, setPath] = useState<string | undefined>()

  useEffect(() => {
    const isPartner = signup?.isPartnerLead
    if (isPartner) {
      setPath(`/signup/qualified/${signup.lead_id}`)
    } else {
      const path = Object.entries(redirectDictionary)
        .reverse()
        .find(([_path, condition]) => condition({ ...signup, ...selfSignup }))?.[0]
      setPath(path)
    }
  }, [signup, selfSignup])

  return {
    continueSignupAvailable: path && path?.length > 0,
    redirect: () => {
      if (path) router.push(path)
    },
  }
}
