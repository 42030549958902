//@ts-nocheck
import React, { FC } from 'react';
import { TextFieldProps as MUITextFieldProps } from '@mui/material';
import { StyledTextField } from './styles';

type TextFieldProps = Omit<MUITextFieldProps, 'variant'>;

export const TextField: FC<TextFieldProps> = ({
  children,
  ...textFieldProps
}) => {
  return (
    <StyledTextField
      variant="filled"
      {...textFieldProps}
      InputProps={{
        ...textFieldProps.InputProps,
        disableUnderline: true,
      }}
      sx={{
        ...textFieldProps.sx,
        '& .MuiInputBase-root': {
          ...(textFieldProps.sx?.['& .MuiInputBase-root'] ?? {}),
          outline: `${textFieldProps.value ?? ''}`.length
            ? 'white solid 1px'
            : '',
        },
      }}
      placeholder={
        textFieldProps.placeholder ?? textFieldProps.label?.toString() ?? ''
      }
      label=""
    >
      {children}
    </StyledTextField>
  );
};
