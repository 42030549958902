import { useMutation } from 'react-query'
import { put } from '..'
import { useSignupData } from '../signup'
import { useSelfSignupData } from './selfSignup.atom'

export type VerifyEmailQuery = {
  email: string
  phone: string
  owner_no: 0 | 1
  referral_code?: string
  colocated?: boolean
  consent?: boolean
}
export type VerifyEmailResponse = {
  result: 'OK'
  opportunity_id: string
}
export type VerifyEmailError = {
  result: 'FAIL'
  message: string
}

export const useVerifyEmail = () => {
  const { setSelfSignup } = useSelfSignupData()
  const { signup } = useSignupData()
  return useMutation(
    'VerifyEmail',
    (data: Omit<VerifyEmailQuery, 'consent'>) =>
      put<VerifyEmailResponse, VerifyEmailQuery>(`/v2/signup/verifyInfo/${signup.lead_id ?? ''}`, {
        ...data,
        consent: true,
      }),
    {
      onSuccess: (data) => {
        setSelfSignup({
          opportunity_id: data.opportunity_id,
        })
      },
    }
  )
}
