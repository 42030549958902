import { NavLink } from 'ui/layout/header/navlink'

export const routes: NavLink[] = [
  {
    href: '/',
    label: 'Home',
  },
  {
    href: '/about#mission',
    label: 'About Us',
  },
  {
    href: 'https://blog.homeoptions.com',
    label: 'Blog',
  },
]
