import { TextField, styled, TextFieldProps } from '@mui/material';
import { theme } from '../theme';
import { StyledComponent } from '@emotion/styled';

export const StyledTextField: StyledComponent<TextFieldProps> = styled(
  TextField
)(() => ({
  fontFamily: theme.typography.fontFamily,
  '& .MuiInputBase-root.MuiInputBase-multiline': {
    padding: '8px 12px',
  },
  '& .MuiFormHelperText-root': {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.error.main,
  },
  '& .MuiFilledInput-root.Mui-error': {
    border: `3px solid ${theme.palette.error.main}`,
    borderRadius: 8,
    backgroundColor: 'transparent',
  },
  '& .MuiFilledInput-root': {
    fontSize: '1rem',
    border: `3px solid #D1DCE0`,
    borderRadius: 8,
    backgroundColor: 'transparent',
    '& .Mui-disabled': {
      backgroundColor: theme.palette.text.disabled,
    },
  },
  '& .MuiFilledInput-input': {
    paddingTop: 12,
    paddingBottom: 12,
    color: theme.palette.text.primary,
    '&::placeholder': {
      color: theme.palette.text.disabled,
      opacity: 1,
    },
  },
  '& .MuiSvgIcon-root': {
    marginRight: 6,
  },
  ':hover': {
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.text.disabled,
    },
  },

  ':focus': {
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.text.primary,
    },
  },
}));
