import { ThemeProvider } from '@homeoptions/dev.theme'
import { routes } from 'assets/static/routes'
import { ContinueMySignupBar } from 'components/ContinueMySignupBar'
import { MouseFlowLink } from 'components/MouseFlowLink'
import { useTestingMode } from 'context/state/testing.atom'
import { AtomsDevtools } from 'context/util/devTools'
import { Provider } from 'jotai'
import { useExperiments } from 'hooks/useExperiments'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ReactElement, StrictMode, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Footer, Header, HomeOptionsBrand } from 'ui'
import { NavLink } from 'ui/layout/header/navlink'
import '../styles/globals.css'

const Toastifier = dynamic<any>(() =>
  import('@homeoptions/dev.layout.toastifier').then((m) => m.Toastifier)
)

const queryClient = new QueryClient()

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (_page: ReactElement) => ReactElement
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function Shell(props: AppPropsWithLayout) {
  return (
    <StrictMode>
      <ThemeProvider>
        <Provider>
          <AtomsDevtools>
            <QueryClientProvider client={queryClient}>
              <MyApp {...props} />
            </QueryClientProvider>
          </AtomsDevtools>
        </Provider>
      </ThemeProvider>
    </StrictMode>
  )
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  useExperiments()
  const { testingMode } = useTestingMode()
  const router = useRouter()
  const combinedRoutes = [
    ...routes,
    ...(testingMode ? [{ href: '/internal_testing', label: 'Test Mode ACTIVE' }] : []),
  ]

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID)
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })
  }, [])

  return Component.getLayout ? (
    Component.getLayout(<Component {...pageProps} />)
  ) : (
    <>
      <Head>
        <meta name="title" content="HomeOptions" />
        <meta
          name="description"
          key="description"
          content="HomeOptions uses simple, straightforward, and transparent approach by offering you cash upfront for the right to sell your home in the future."
        />
        <link rel="icon" href="/favicon.svg" />
        <meta property="og:image" content="https://homeoptions.com/images/logo.svg" />
      </Head>
      <Header
        location={router.pathname}
        logo={
          <MouseFlowLink href="/">
            <HomeOptionsBrand className="h-9 w-auto" />
          </MouseFlowLink>
        }
      >
        {combinedRoutes.map((r, i) => (
          <MouseFlowLink key={i} href={r.href!}>
            <NavLink label={r.label} />
          </MouseFlowLink>
        ))}
      </Header>
      <Component {...pageProps} />
      <Footer />
      <Toastifier />
      <ContinueMySignupBar />
    </>
  )
}

export default Shell
