import { SendRounded } from '@mui/icons-material'
import { useState } from 'react'
import { IconButton } from 'ui/design-system/icon-button'

export type MessageSendContainerProps = {
  placeholder?: string
  disabled?: boolean
  onSubmit?: (message: string) => void
}

export const MessageSendContainer = ({
  onSubmit,
  disabled,
  placeholder,
}: MessageSendContainerProps) => {
  const [message, setMessage] = useState('')
  const rows = message.split(/\r?\n|\r|\n/g).length

  return (
    <form
      className="flex w-full h-fit gap-2 items-center"
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit && onSubmit(message)
        setMessage('')
      }}
    >
      <textarea
        value={message}
        onChange={(e) => setMessage(e.currentTarget.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            onSubmit && onSubmit(message)
            setMessage('')
          }
        }}
        disabled={disabled}
        className={`flex-1 font-body h-fit p-2 placeholder-gray-600 bg-brand-blue-light rounded 
                    border-2 border-brand-blue resize-none overflow-hidden 
                    disabled:bg-gray-200 disabled:border-gray-500`}
        placeholder={placeholder ?? 'Questions? Chat here now!'}
        aria-multiline="true"
        rows={rows}
      />
      <IconButton type="submit" disabled={disabled}>
        <SendRounded />
      </IconButton>
    </form>
  )
}
