export type NavLink = {
  href?: string
  label: string
  section?: string
  className?: string
}

export const NavLink = ({ label, className }: NavLink) => {
  return <span className={`font-bold text-lg ${className ?? ''}`}>{label}</span>
}
