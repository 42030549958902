import React, { HTMLAttributes } from 'react';

export const HomeOptionsBrand = (props: HTMLAttributes<SVGElement>) => (
  <svg
    width="3921"
    height="572"
    viewBox="0 0 3921 572"
    fill="none"
    {...props}
  >
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.674805 225.345L268.585 0.11792L533.188 225.345V563.185H0.674805V225.345Z"
        fill="#5B5FD4"
      />
      <path
        d="M266.937 225.345L534.847 0.11792L799.45 225.345V563.185H266.937V225.345Z"
        fill="#969AFC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M266.937 563.182H533.193V225.342L400.892 112.729L266.937 225.342V563.182Z"
        fill="#69DDEA"
      />
      <path
        d="M1428.17 406.443C1445.46 406.443 1460.26 400.143 1472.57 387.542C1485.17 374.649 1491.47 356.628 1491.47 333.479C1491.47 310.036 1485.31 292.015 1473.01 279.415C1460.7 266.814 1445.75 260.514 1428.17 260.514C1410.88 260.514 1396.23 266.814 1384.22 279.415C1372.2 292.015 1366.2 310.036 1366.2 333.479C1366.2 356.921 1372.2 374.942 1384.22 387.542C1396.23 400.143 1410.88 406.443 1428.17 406.443ZM1338.95 424.024C1315.21 400.875 1303.34 370.547 1303.34 333.039C1303.34 295.531 1315.36 265.349 1339.39 242.493C1363.41 219.637 1393.16 208.209 1428.61 208.209C1464.36 208.209 1494.25 219.637 1518.28 242.493C1542.31 265.349 1554.32 295.531 1554.32 333.039C1554.32 370.547 1542.16 400.875 1517.84 424.024C1493.81 447.174 1463.92 458.748 1428.17 458.748C1392.42 458.748 1362.68 447.174 1338.95 424.024Z"
        fill="currentColor"
      />
      <path
        d="M1644.54 211.725V248.207C1660.07 221.542 1685.27 208.209 1720.14 208.209C1738.6 208.209 1755.01 212.458 1769.37 220.955C1784.02 229.453 1795.31 241.614 1803.22 257.438C1811.72 242.2 1823.29 230.186 1837.94 221.395C1852.89 212.604 1869.59 208.209 1888.05 208.209C1917.06 208.209 1940.35 217.293 1957.94 235.46C1975.81 253.628 1984.75 279.122 1984.75 311.941V455.232H1923.21V320.732C1923.21 301.685 1918.38 287.18 1908.71 277.217C1899.04 266.961 1885.85 261.833 1869.15 261.833C1852.45 261.833 1839.11 266.961 1829.15 277.217C1819.48 287.18 1814.65 301.685 1814.65 320.732V455.232H1753.11V320.732C1753.11 301.685 1748.27 287.18 1738.6 277.217C1728.93 266.961 1715.75 261.833 1699.05 261.833C1682.34 261.833 1669.01 266.961 1659.05 277.217C1649.38 287.18 1644.54 301.685 1644.54 320.732V455.232H1582.57V211.725H1644.54Z"
        fill="currentColor"
      />
      <path
        d="M2131.12 458.748C2095.66 458.748 2066.8 447.32 2044.53 424.464C2022.26 401.315 2011.12 370.84 2011.12 333.039C2011.12 295.238 2022.26 265.056 2044.53 242.493C2067.09 219.637 2096.1 208.209 2131.56 208.209C2167.31 208.209 2196.61 219.344 2219.47 241.614C2242.32 263.591 2253.75 293.041 2253.75 329.962C2253.75 338.46 2253.17 346.372 2251.99 353.697H2074.42C2075.88 369.521 2081.74 382.268 2092 391.938C2102.26 401.608 2115.3 406.443 2131.12 406.443C2152.51 406.443 2168.33 397.652 2178.59 380.07H2245.4C2238.37 403.219 2224.74 422.12 2204.52 436.771C2184.3 451.423 2159.84 458.748 2131.12 458.748ZM2189.58 313.699C2188.7 297.582 2182.69 284.689 2171.56 275.019C2160.72 265.349 2147.53 260.514 2132 260.514C2116.47 260.514 2103.43 265.349 2092.88 275.019C2082.62 284.689 2076.47 297.582 2074.42 313.699H2189.58Z"
        fill="currentColor"
      />
      <path
        d="M2510.45 374.357C2527.74 355.897 2536.38 331.429 2536.38 300.954C2536.38 270.479 2527.74 246.157 2510.45 227.99C2493.16 209.822 2470.6 200.738 2442.76 200.738C2414.92 200.738 2392.21 209.968 2374.63 228.429C2357.34 246.597 2348.7 270.918 2348.7 301.393C2348.7 331.575 2357.34 355.897 2374.63 374.357C2392.21 392.525 2414.92 401.609 2442.76 401.609C2470.6 401.609 2493.16 392.525 2510.45 374.357ZM2442.76 458.31C2398.51 458.31 2361.3 443.512 2331.12 413.916C2300.93 384.32 2285.84 346.813 2285.84 301.393C2285.84 255.681 2300.93 218.027 2331.12 188.431C2361.59 158.835 2398.81 144.037 2442.76 144.037C2487.01 144.037 2524.22 158.835 2554.4 188.431C2584.59 218.027 2599.68 255.681 2599.68 301.393C2599.68 346.813 2584.59 384.32 2554.4 413.916C2524.22 443.512 2487.01 458.31 2442.76 458.31Z"
        fill="currentColor"
      />
      <path
        d="M2802.96 385.784C2815.86 372.891 2822.3 355.309 2822.3 333.039C2822.3 310.769 2815.86 293.334 2802.96 280.733C2790.36 268.133 2775.42 261.833 2758.13 261.833C2740.84 261.833 2725.75 268.28 2712.86 281.173C2700.26 293.773 2693.96 311.208 2693.96 333.478C2693.96 355.749 2700.26 373.33 2712.86 386.224C2725.75 398.824 2740.84 405.124 2758.13 405.124C2775.42 405.124 2790.36 398.677 2802.96 385.784ZM2693.96 249.526C2711.25 221.981 2736.59 208.209 2770 208.209C2803.4 208.209 2830.95 219.93 2852.63 243.372C2874.32 266.521 2885.16 296.557 2885.16 333.478C2885.16 370.107 2874.32 400.142 2852.63 423.585C2830.95 447.027 2803.55 458.748 2770.44 458.748C2737.62 458.748 2712.12 443.95 2693.96 414.354V571.271H2631.98V211.725H2693.96V249.526Z"
        fill="currentColor"
      />
      <path
        d="M3020.09 403.363H3049.54V455.229H3010.42C2985.52 455.229 2966.32 449.515 2952.84 438.087C2939.36 426.659 2932.62 407.759 2932.62 381.386V262.27H2906.69V211.723H2932.62V151.505H2994.6V211.723H3049.1V262.27H2994.6V381.386C2994.6 389.298 2996.5 395.012 3000.31 398.528C3004.12 401.752 3010.72 403.363 3020.09 403.363Z"
        fill="currentColor"
      />
      <path
        d="M3157.67 149.749C3157.67 160.298 3154.01 169.235 3146.68 176.561C3139.36 183.887 3130.42 187.55 3119.87 187.55C3109.62 187.55 3100.83 183.887 3093.5 176.561C3086.17 169.235 3082.51 160.298 3082.51 149.749C3082.51 139.2 3086.17 130.263 3093.5 122.937C3100.83 115.611 3109.62 111.948 3119.87 111.948C3130.42 111.948 3139.36 115.611 3146.68 122.937C3154.01 130.263 3157.67 139.2 3157.67 149.749ZM3089.1 455.231V211.724H3151.08V455.231H3089.1Z"
        fill="currentColor"
      />
      <path
        d="M3306.24 406.443C3323.53 406.443 3338.33 400.143 3350.63 387.542C3363.23 374.649 3369.53 356.628 3369.53 333.479C3369.53 310.036 3363.38 292.015 3351.07 279.415C3338.77 266.814 3323.82 260.514 3306.24 260.514C3288.95 260.514 3274.3 266.814 3262.29 279.415C3250.27 292.015 3244.26 310.036 3244.26 333.479C3244.26 356.921 3250.27 374.942 3262.29 387.542C3274.3 400.143 3288.95 406.443 3306.24 406.443ZM3217.01 424.024C3193.28 400.875 3181.41 370.547 3181.41 333.039C3181.41 295.531 3193.42 265.349 3217.45 242.493C3241.48 219.637 3271.22 208.209 3306.68 208.209C3342.43 208.209 3372.32 219.637 3396.35 242.493C3420.38 265.349 3432.39 295.531 3432.39 333.039C3432.39 370.547 3420.23 400.875 3395.91 424.024C3371.88 447.174 3341.99 458.748 3306.24 458.748C3270.49 458.748 3240.75 447.174 3217.01 424.024Z"
        fill="currentColor"
      />
      <path
        d="M3523.59 211.725V248.207C3539.13 221.542 3564.33 208.209 3599.2 208.209C3627.03 208.209 3649.74 217.439 3667.33 235.9C3684.91 254.068 3693.7 279.415 3693.7 311.941V455.232H3632.16V320.732C3632.16 301.685 3627.33 287.18 3617.66 277.217C3607.99 266.961 3594.8 261.833 3578.1 261.833C3561.4 261.833 3548.06 266.961 3538.1 277.217C3528.43 287.18 3523.59 301.685 3523.59 320.732V455.232H3461.62V211.725H3523.59Z"
        fill="currentColor"
      />
      <path
        d="M3720.29 288.425C3720.29 267.327 3729.23 249.599 3747.1 235.24C3764.98 220.882 3788.71 213.703 3818.31 213.703C3847.9 213.703 3871.64 220.882 3889.51 235.24C3907.39 249.306 3916.91 268.499 3918.08 292.82H3854.79C3853.03 272.308 3840.43 262.052 3816.99 262.052C3805.27 262.052 3796.18 264.396 3789.74 269.085C3783.29 273.773 3780.07 280.22 3780.07 288.425C3780.07 296.63 3784.9 303.076 3794.57 307.765C3804.24 312.453 3815.96 316.116 3829.74 318.753C3843.51 321.391 3857.13 324.76 3870.61 328.863C3884.39 332.672 3896.11 339.705 3905.78 349.961C3915.45 359.924 3920.28 373.257 3920.28 389.959C3920.28 411.936 3910.9 429.811 3892.15 443.584C3873.69 457.356 3849.95 464.242 3820.94 464.242C3792.23 464.242 3768.64 457.502 3750.18 444.023C3731.72 430.251 3721.61 410.618 3719.85 385.124H3783.14C3785.49 405.636 3798.53 415.892 3822.26 415.892C3833.69 415.892 3842.92 413.402 3849.95 408.42C3856.99 403.146 3860.5 396.406 3860.5 388.201C3860.5 379.703 3855.67 373.11 3846 368.422C3836.33 363.44 3824.61 359.631 3810.83 356.994C3797.06 354.356 3783.29 351.133 3769.52 347.324C3756.04 343.514 3744.46 336.775 3734.79 327.105C3725.12 317.142 3720.29 304.248 3720.29 288.425Z"
        fill="currentColor"
      />
      <path
        d="M1211.62 148.432H1273.59V455.233H1211.62V323.81H1079.75V455.233H1017.78V148.432H1079.75V276.339H1211.62V148.432Z"
        fill="currentColor"
      />
    </svg>
  </svg>
);
