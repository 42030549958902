import Link from 'next/link'
import { ReactNode } from 'react'

export type MouseFlowLinkProps = {
  href: string
  children: ReactNode
}
export const MouseFlowLink = ({ href, children }: MouseFlowLinkProps) => {
  return (
    <Link
      href={href}
      onClick={() => {
        ;(window as any)?._mfq?.push(['newPageView', href])
      }}
    >
      {children}
    </Link>
  )
}
