export default `
<h2><b>Terms and Conditions</b></h2>
<h3><b>Terms of Service</b></h3>
<p>This Terms of Service shall
serve to explain our expectations for how you as a Licensed Real Estate Agent,
a related third party or as a buyer/seller interact with and utilize our
website. HomeOptions, Inc. is a Delaware incorporation, doing business as
HomeOptions, and its subsidiaries and affiliates (collectively, “HomeOptions”,
“we,” “us”, or “our”) when you access or use our website (the “Site”) and our
agent matching service (the “Service”), which are provided through the Site,
you consent to the following Terms of Service. Supplemental terms and
conditions or documents that may be posted on the Site from time to time are
hereby expressly incorporated herein by reference. We reserve the right, in our
sole discretion, to make changes or modifications to these Terms of Service at
any time and for any reason. We will alert you about any changes by updating
the “Last updated” date of these Terms of Service, and you waive any right to
receive specific notice of each such change. It is your responsibility to
periodically review these Terms of Service to stay informed of updates. You
will be subject to, and will be deemed to have been made aware of and to have
accepted, the changes in any revised Terms of Service by your continued use of
the Site after the date such revised Terms and Conditions are posted.</p>
<p>The information provided on the Site
is not intended for distribution to or use by any person or entity in any
jurisdiction or country where such distribution or use would be contrary to law
or regulation or which would subject us to any registration requirement within
such jurisdiction or country. Accordingly, those persons who choose to access
the Site from other locations do so on their own initiative and are solely
responsible for compliance with local laws, if and to the extent local laws are
applicable.</p>
<p>The Site is intended for users who
are at least 18 years old. Persons under the age of 18 are not permitted to use
or register for the Site.</p>

<h3><b>Intellectual Property Rights</b></h3>
<p>Unless otherwise indicated, the Site
is our proprietary property and all source code, databases, functionality,
software, website designs, audio, video, text, photographs, and graphics on the
Site (collectively, the “Content”) and the trademarks, service marks, and logos
contained therein (the “Marks”) are owned or controlled by us or licensed to
us, and are protected by copyright and trademark laws and various other
intellectual property rights and unfair competition laws of the United States,
foreign jurisdictions, and international conventions. The Content and the Marks
are provided on the Site “AS IS” for your information and personal use only.
Except as expressly provided in these Terms of Service, no part of the Site and
no Content or Marks may be copied, reproduced, aggregated, republished,
uploaded, posted, publicly displayed, encoded, translated, transmitted,
distributed, sold, licensed, or otherwise exploited for any commercial purpose
whatsoever, without our express prior written permission.</p>
<p>Provided that you are eligible to
use the Site, you are granted a limited license to access and use the Site and
to download or print a copy of any portion of the Content to which you have
properly gained access solely for your personal, non-commercial use. We reserve
all rights not expressly granted to you in and to the Site, the Content and the
Marks.</p>

<h3><b>Information We Collect and How We Collect It</b></h3>

<p>HomeOptions is a service that
connects home sellers to top real estate agents around the US and serves as a
communication conduit between both aforementioned parties. The service that we
provide to both sellers of real estate and real estate professionals is
governed by a few terms that are outlined below.</p>
<p>Because we are an online service, we
don’t ask you to deal with and physically sign a lengthy contract. The terms of
our contract with you are set forth below in plain language. If you don’t agree
with the terms, then we ask you please to leave this Site. If you do use this
Site, then that action will serve as your agreement to be bound by our terms of
service. You agree that clicking “Get Started” (or other such button you use to
submit your information) acts as your electronic signature. You further consent
to receive emails, phone calls and messages from HomeOptions, Inc. and its
affiliates regarding your inquiry including by recorded and autodialed calls
and text messages. Carrier data and message rates may apply. You may opt out at
any time by calling 1-800-357-9992 or by emailing contact@homeoptions.ai.</p>
<p>We will maintain certain data that
you transmit to the Site for the purpose of managing the performance of the
Site, as well as data relating to your use of the Site. Although we perform
regular routine backups of data, you are solely responsible for all data that
you transmit or that relates to any activity you have undertaken using the Site.
You agree that we shall have no liability to you for any loss or corruption of
any such data, and you hereby waive any right of action against us arising from
any such loss or corruption of such data. If you provide any information that
is untrue, inaccurate, not current, or incomplete, we have the right to suspend
or terminate your account and refuse any and all current or future use of the
Site (or any portion thereof).</p>
<p>HomeOptions is a web service that is
wholly owned and operated by HomeOptions, Inc. in partnership with licensed
real estate brokerages across the US and is in compliance with all state and
federal housing laws.</p>
<p>Real estate professionals who are
involved with and use our Site must agree with the following terms:</p>
<p>You grant HomeOptions permission to
display on our Site and elsewhere information that we have gathered or you have
supplied related to any of the real estate transactions that you have handled
as a real estate professional. This information helps our mutual clients better
understand your strengths and sales performance history.</p>
<p>Our platform is referral based, and
during the sign-up process you will digitally sign a referral agreement with
us. When we connect you with a referral then you agree to pay us a Referral Fee
on the gross commission your firm earns on each transaction involving the
prospect. This agreement is valid and enforceable for multiple transactions,
and it is your obligation to keep us informed of your progress with our
referred clients. The terms for this Referral Fee (the fine print) are located
on your Profile page under the “Agreement” icon. Referral fees are common in
real estate and without them, our service would not be possible.</p>
<p>You are responsible for maintaining
your own professional liability insurance policy, a valid real estate license
and for following all applicable real estate laws regarding disclosures,
documentation and other brokerage responsibilities. In addition, you are
responsible for the real estate brokerage services provided to your clients and
agree to indemnify, defend and hold HomeOptions harmless from any claims,
costs, and damages incurred by us arising from claims by your clients regarding
the brokerage services you have provided.</p>
<p>Your participation in our service is
voluntary and can be terminated by either of us for any reason at any time with
written notice. However, any referrals made prior to such termination are still
bound by this agreement, and Referral Fees will be due upon close of any
transactions resulting from such referrals.</p>
<p>In connection with the referrals,
you agree to be contacted by HomeOptions and its referrals via phone, email,
SMS text message, messaging services such as WhatsApp service, mail, or other
reasonable means.</p>
<p>You further agree that you will not
forward our referrals to any other party without our written consent. Any
referral fee that you earn by forwarding our clients to a third-party brokerage
must be paid in full to HomeOptions within 10 days of receipt of such funds.</p>
<p>Any information that you provide to
us shall be accurate, complete and owned by you, and you agree to update any
information that is or becomes inaccurate. Of course, if we discover that any
information is inaccurate, we may correct it ourselves. You hereby grant us
permission to e-mail or display your Profile (including your name, likeness,
contact information and transaction details) and such other information as may
be supplied by you on or from our Site “homeoptions.ai” and such other partner
and affiliate websites as we deem appropriate.</p>
<p>You agree that we may modify the
services provided, these terms of service or the price charged for our services
at any time. We will send you an email thirty (30) days in advance of any
significant changes of our services, terms of service or prices, and you agree
that your continued use of our services after the notice period means that you
accept the new terms and any subsequent referrals or services shall be governed
by the new terms.</p>
<p>You agree that these public “terms
of service” compliment and are in addition to the terms of service contained
with our fully executed and digitally signed referral agreement housed in your
account.</p>

<h3><b>Terms for Buyers and Sellers</b></h3>

<p>All non-real estate professionals
(including prospective Buyers and Sellers and their representatives whether
personal or professional) who are involved with and use our Site must agree
with the following terms:</p>
<p>Our purpose as a data-driven agent
matching service is to make a professional introduction between you and our
Real Estate Agent partners. Of course, this introduction involves sharing the
contact information of each party with the other. When you submit information
to us, you authorize us to use and provide this information to make an
appropriate match. By providing this information to us, you consent to being
contacted by us and by our partner real estate professionals via phone, email,
SMS text message, messaging services such as WhatsApp service, mail, or other
reasonable means.</p>
<p>In order to best help you, we need
you to provide information that is accurate and complete, especially your
contact information so that our Real Estate Agent partners can contact you
quickly and efficiently. We reserve the right to supplement, amend, or correct
inaccurate submissions or to suspend or terminate access to anyone who supplies
information that is intentionally inaccurate or misleading.</p>
<p>You understand that for our services
we may receive payment (a referral fee) that may be a percentage of the
commission received by the real estate professionals involved in the real
estate transaction. Your use of our services constitutes your acknowledgment
of, and agreement to, this compensation arrangement.</p>
<p>You agree that we may modify the
services provided or these terms of service at any time. We will notify you in advance
of any significant changes of our services, and you agree that your continued
use of our services after the notice period means that you accept the new terms
and any subsequent services shall be governed by the new terms.</p>

<h3><b>Additional Terms</b></h3>

<p>All who are involved with and use
our Site must agree with the following additional terms:</p>
<p>HomeOptions intends that the
information contained on our Site be accurate and reliable; however, errors
sometimes occur. In addition, we may make changes and improvements to the
information provided at any time. Accordingly, we do not guarantee the accuracy
of any information available on this Site, and are not responsible for any
errors, omissions, or misrepresentations and any information should be
independently verified.</p>
<p>You agree to the following
limitation: THE WEB SITE AND THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES
ASSOCIATED WITH IT ARE PROVIDED “AS IS.” HOMEOPTIONS AND/OR ITS SUPPLIERS,
AFFILIATED THIRD-PARTY PARTNERS, OR REAL ESTATE PROFESSIONALS DISCLAIM ANY
WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED, AS TO ANY MATTER WHATSOEVER
RELATING TO THE WEB SITE AND ANY INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES
PROVIDED HEREIN, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT.
USE OF OUR SERVICE IS AT YOUR OWN RISK. WE AND/OR OUR SUPPLIERS OR PARTNERS,
ARE NOT LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR
CONSEQUENTIAL DAMAGES OR OTHER INJURY ARISING OUT OF OR IN ANY WAY CONNECTED
WITH THE USE OF OUR SERVICES OR WITH THE DELAY OR INABILITY TO USE THE WEB
SITE, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES OBTAINED THROUGH
THE WEB SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE WEB SITE, WHETHER RESULTING
IN WHOLE OR IN PART, FROM BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE,
STRICT LIABILITY, OR OTHERWISE, EVEN IF WE AND/OR ITS SUPPLIERS HAD BEEN
ADVISED OF THE POSSIBILITY OF DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE
EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.</p>
<p>You also agree to the following: IN
NO EVENT SHALL WE OR OUR SUPPLIERS BE LIABLE FOR LOST PROFITS OR ANY SPECIAL,
INCIDENTAL, OR CONSEQUENTIAL DAMAGES (HOWEVER ARISING, INCLUDING NEGLIGENCE)
ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT. OUR LIABILITY, AND THE
LIABILITY OF OUR SUPPLIERS AND PARTNERS, TO YOU OR ANY THIRD PARTIES IN ANY
CIRCUMSTANCE, IS LIMITED TO $100. Some states do not allow the limitation of
liability, so the foregoing limitation may not always apply.</p>
<p>The Site may contain (or you may be
sent via the Site) links to other websites (&quot;Third-Party Websites&quot;)
as well as articles, photographs, text, graphics, pictures, designs, music,
sound, video, information, applications, software, and other content or items
belonging to or originating from third parties (&quot;Third-Party
Content&quot;). Such Third-Party Websites and Third-Party Content are not
investigated, monitored, or checked for accuracy, appropriateness, or
completeness by us, and we are not responsible for any Third-Party Websites
accessed through the Site or any Third-Party Content posted on, available
through, or installed from the Site, including the content, accuracy,
offensiveness, opinions, reliability, privacy practices, or other policies of
or contained in the Third-Party Websites or the Third-Party Content. Inclusion
of, linking to, or permitting the use or installation of any Third-Party
Websites or any Third-Party Content does not imply approval or endorsement
thereof by us. If you decide to leave the Site and access the Third-Party
Websites or to use or install any Third-Party Content, you do so at your own
risk, and you should be aware these Terms of Service no longer govern. You
should review the applicable terms and policies, including privacy and data
gathering practices, of any website to which you navigate from the Site or
relating to any applications you use or install from the Site. Any purchases
you make through Third-Party Websites will be through other websites and from
other companies, and we take no responsibility whatsoever in relation to such
purchases which are exclusively between you and the applicable third party. You
agree and acknowledge that we do not endorse the products or services offered
on Third-Party Websites and you shall hold us harmless from any harm caused by
your purchase of such products or services. Additionally, you shall hold us
harmless from any losses sustained by you or harm caused to you relating to or
resulting in any way from any Third-Party Content or any contact with
Third-Party Websites.</p>
<p>We cannot guarantee the Site will be
available at all times. We may experience hardware, software, or other problems
or need to perform maintenance related to the Site, resulting in interruptions,
delays, or errors. We reserve the right to change, revise, update, suspend,
discontinue, or otherwise modify the Site at any time or for any reason without
notice to you. You agree that we have no liability whatsoever for any loss,
damage, or inconvenience caused by your inability to access or use the Site
during any downtime or discontinuance of the Site. Nothing in these Terms of
Service will be construed to obligate us to maintain and support the Site or to
supply any corrections, updates, or releases in connection therewith.</p>
<p>You also agree to the following:
These Terms of Service shall be subject to and construed in accordance with the
laws of the State of Delaware, without regard to its conflict of laws
principles. If any part of these Terms of Service are determined to be invalid
or unenforceable pursuant to applicable law including, but not limited to, the
warranty disclaimers and liability limitations set forth above, then the
invalid or unenforceable provision will be deemed superseded by a valid
enforceable provision that most closely matches the intent of the original
provision, and the remainder of the Agreement shall continue in effect. A
printed version of this Agreement and of any notice given in electronic form
shall be admissible in judicial or administrative proceedings based upon or
relating to this agreement to the same extent and subject to the same
conditions as other business documents and records originally generated and
maintained in printed form. All rights not expressly granted herein are
reserved to HomeOptions, Inc.</p></p></p>

<p><p>You also agree to the following: </p><p>(1) Any claim, dispute,
or controversy (whether in contract, tort, or otherwise) arising out of,
relating to, or connected in any way with the website or the services provided
on the website will be resolved exclusively by final and binding arbitration
conducted pursuant to the American Arbitration Association (“AAA”) Consumer
Arbitration Rules (if and as applicable depending on the amount in
controversy); (2) This arbitration agreement is made pursuant to a transaction
governed by the Federal Arbitration Act (“FAA”), 9 U.S.C. §§ 1-16; (3) The
arbitration will be held in your county of residence; (4) The arbitrator’s
decision will be controlled by the terms and conditions of this Agreement; (5)
The arbitrator will apply Delaware law consistent with the FAA and applicable
statutes of limitations and will honor claims of privilege recognized at law;
(6) There will be no authority for any claims to be arbitrated on a class or representative
basis; arbitration can decide only your individual claims and the arbitrator
may not consolidate or join the claims of other persons or parties who may be
similarly situated; (7) The arbitrator will not have the power to award
punitive, consequential or exemplary damages against any party; (8) In the
event that the administrative fees and deposits you are required to pay under
the AAA rules exceed $125, and you are unable to pay the additional fees and
deposits, Company retains the right to forward them to the AAA on the your
behalf, subject to ultimate allocation by the arbitrator. In addition, if you
are able to demonstrate that the costs of arbitration will be prohibitive as
compared to the costs of litigation, Company retains the right to pay as much
of your filing and hearing fees in connection with the arbitration as the
arbitrator deems necessary to prevent the arbitration from being
cost-prohibitive; and (9) If any part of this Arbitration Provision is deemed
to be invalid or otherwise unenforceable or illegal, the balance of this
Arbitration Provision will remain in full force and effect and will be
construed in accordance with its terms as if the invalid or illegal provision
were not contained herein. The FAA applies to any disputes over arbitrability
or the enforcement of this clause. <b>THESE TERMS PROVIDE THAT ALL DISPUTES
BETWEEN YOU AND US WILL BE RESOLVED BY BINDING ARBITRATION. THUS, YOU GIVE UP
YOUR RIGHT TO GO TO COURT TO ASSERT OR DEFEND YOUR RIGHTS. YOU ALSO GIVE UP
YOUR RIGHT TO PARTICIPATE IN OR BRING CLASS ACTIONS. YOUR RIGHTS WILL BE
DETERMINED BY A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY. THE INFORMATION
PROVIDED IN THIS AGREEMENT IS NOT INTENDED TO CONTAIN A COMPLETE EXPLANATION OF
THE CONSEQUENCES OF ARBITRATION.</b> You may opt out of this arbitration
agreement by providing notice to us by US Mail sent to: <b>HomeOptions, Inc.</b>
<b>226 2nd St., Oakland, California 94607 </b>no later than 30 calendar days
from the date you accept these Terms of Service. To opt out, you must send
notice in a letter that includes the subject line: “Arbitration Opt Out” along
with your full name and the email address you used when consenting to the Terms
of Service.</p></p>
`;
