import { useMutation } from 'react-query'
import { post } from 'context/api'

export type SendExperimentSessionQuery = {
  id: string
  experiment_id: string
  start_date: string
  bucket: string
  entrypoint: string
  browser_session_id: string
  lead_id: string
}
export type SendExperimentSessionResponse = {
  result: 'OK'
  details: string
}
export type SendExperimentSessionError = {
  result: 'FAIL'
  reason: string
}

export const useSendExperimentSession = () =>
  useMutation('experimentSession', (data: SendExperimentSessionQuery) =>
    post<SendExperimentSessionResponse, SendExperimentSessionQuery>(`/details/experiment_session`, data)
  )
