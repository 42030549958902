import { Button } from '../../design-system/button'

export type ChatActionButtonProps = {
  onClick?: () => void
  className?: string
  children?: React.ReactNode
  isCta?: boolean
  variant?: 'contained' | 'outlined'
}

export const ChatActionButton = ({
  children,
  onClick,
  isCta,
  className,
  variant,
}: ChatActionButtonProps) => {
  return (
    <Button
      className={`${isCta ? 'w-full' : ''} max-w-lg self-center ${className ?? ''}`}
      variant={variant ?? 'contained'}
      color={isCta ? 'secondary' : 'primary'}
      onClick={() => onClick && onClick()}
    >
      {children}
    </Button>
  )
}
